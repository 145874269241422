import Button from 'components/common/Button'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'
import { ReactNode } from 'react'

const NoChargeConfirmationModal = ({
  children,
  onCloseModal,
}: {
  children: ReactNode
  onCloseModal(): void
}) => {
  return <Modal onCloseModal={onCloseModal}>{children}</Modal>
}

export default NoChargeConfirmationModal

export const AddMarketingCreditsModalBody = ({
  onCloseModal,
  onConfirmationClick,
}: {
  onCloseModal(): void
  onConfirmationClick(): void
}) => {
  return (
    <ModalBody>
      <ModalHeader onClickClose={onCloseModal}>
        Add Marketing Credits
      </ModalHeader>
      <div className="max-w-[700px] w-full space-y-8 p-4">
        <div className="space-y-4">
          <p>
            This order was provided to the user free of charge, are you sure you
            want to add a marketing credit?
          </p>
          <p>Check user tags, RTG, and user notes for special guidance.</p>
        </div>

        <div className="mt-4 flex space-x-4 justify-between">
          <Button
            buttonStyle="stroke"
            onClick={onConfirmationClick}
            size="large"
            type="button"
          >
            Yes, continue to Add Marketing Credits
          </Button>
          <Button onClick={onCloseModal} size="large" type="button">
            No, cancel
          </Button>
        </div>
      </div>
    </ModalBody>
  )
}

export const RefundModalBody = ({
  onCloseModal,
  onConfirmationClick,
}: {
  onCloseModal(): void
  onConfirmationClick(): void
}) => {
  return (
    <ModalBody>
      <ModalHeader onClickClose={onCloseModal}>Refund Order</ModalHeader>
      <div className="max-w-[700px] w-full space-y-8 p-4">
        <div className="space-y-4 font-bold">
          <p>
            NOTE: This order was provided free of charge, no payment will be
            refunded. Refunding this order may allow the user to receive another
            order free of charge.
          </p>
          <p>Check user tags, RTG, and user notes for special guidance.</p>
        </div>

        <div className="mt-4 flex justify-end">
          <Button onClick={onConfirmationClick} size="large" type="button">
            OK
          </Button>
        </div>
      </div>
    </ModalBody>
  )
}

export const CancelModalBody = ({
  onCloseModal,
  onConfirmationClick,
}: {
  onCloseModal(): void
  onConfirmationClick(): void
}) => {
  return (
    <ModalBody>
      <ModalHeader onClickClose={onCloseModal}>Cancel Order</ModalHeader>
      <div className="max-w-[700px] w-full space-y-8 p-4">
        <div className="space-y-4 font-bold">
          <p>
            NOTE: This order was provided free of charge, no payment will be
            refunded. Cancelling this order may allow the user to receive
            another order free of charge.
          </p>
          <p>Check user tags, RTG, and user notes for special guidance.</p>
        </div>

        <div className="mt-4 flex justify-end">
          <Button onClick={onConfirmationClick} size="large" type="button">
            OK
          </Button>
        </div>
      </div>
    </ModalBody>
  )
}
