import {
  MealSwap,
  Term,
  TermMenuMeal,
  TermSubTerm,
} from '@tovala/browser-apis-combinedapi'
import { MenuVariantUserAspects } from '@tovala/browser-apis-menu-delivery'
import { flatMap, isEmpty, partition } from 'lodash-es'
import { parse, ParseResult } from 'papaparse'

export interface TPOSTMeal {
  mealID: number | undefined
  menus: {
    name: string
    order: string
  }[]
  misevalaMealVersionID: string
  productionCode: number
  type: string
  version: string
  workingTitle: string
}

interface TPSOTMenuVariant {
  subTermID: string
  description: string
  errors: string[]
  mealIDs: number[]
  mealSwaps: MealSwap[]
  userAspects: string[] | null
}

export type TPSOTMenus = Record<
  string,
  | {
      subTermID: string
      description: string
      errors: string[]
      mealIDs: number[]
      mealSwaps: MealSwap[]
    }
  | {
      subTermID: string
      variants: TPSOTMenuVariant[]
    }
>

interface TPSOTMenuMeal {
  order: number
  mealID: number
  mealSwap?: string
}

interface ImportedMealData {
  expirationDate: string
  facilityNetworks: string[]
  menuIDs: string[]
  misevalaMealVersionID: string
  productionCode: number
  termid: number
  type: string
  workingTitle: string
  version: string
}

const TPSOT_MEAL_ROW_INDEXES = {
  chicago: {
    start: 9, // Row 10
    end: 107, // Row 108
  },
  slc: {
    start: 111, // Row 112
    end: 209, // Row 210
  },
}

const TPSOT_HEADER_ROW_INDEX = 8 // Row 9

const TPSOT_COLUMN_INDEXES = {
  mealID: 3, // Column D, not available on inital upload
  menus: {
    start: 30, // Column AE
    end: 43, // Column AR
  },
  misevalaMealVersionID: 9, // Column J
  productionCode: 1, // Column B
  type: 24, // Column Y
  version: 8, // Column I
  workingTitle: 2, // Column C
}

const ALL_MENU_COLUMN_INDEXES = Array.from(
  {
    length:
      TPSOT_COLUMN_INDEXES.menus.end - TPSOT_COLUMN_INDEXES.menus.start + 1,
  },
  (_value, index) => index + TPSOT_COLUMN_INDEXES.menus.start
)

const TPSOT_COLUMN_USER_ASPECT_MAP = {
  ['core - a']: null,
  ['core - b']: null,
  ['thrive - a']: ['thrive'],
  ['thrive - b']: ['thrive'],
}

const FACILITY_NETWORKS = {
  chicago: 'Chicago',
  slc: 'SLC',
}

export const getMenuSideSwaps = ({
  meals,
  term,
}: {
  meals: { chicago: TPOSTMeal[]; slc: TPOSTMeal[] }
  term: Term
}) => {
  const orderedMenus = flatMap(
    term.subTerms.map((subTerm) => subTerm.menus.map((menu) => menu)) ?? []
  ).sort((a, b) => a.name.localeCompare(b.name))

  const sideSwaps: Record<string, Record<number, number[]>> = {}
  orderedMenus.forEach((menu) => {
    const subTerm = term.subTerms.find(
      (subTerm) => subTerm.id === menu.subtermID
    )

    if (!subTerm) {
      return
    }

    const facilityNetworkMeals: TPOSTMeal[] = meals[subTerm.facilityNetwork]

    const coreMeals = facilityNetworkMeals
      .map((meal: TPOSTMeal) => {
        const menu = meal.menus.find((menu) => menu.name === 'Core - A')
        const termMeal = subTerm.defaultMenu.meals.find(
          (m) => +m.productionCode === meal.productionCode
        )

        if (menu && termMeal) {
          const [order, mealSwap] = menu.order.split('_')
          if (order && order !== '-' && termMeal.id) {
            return {
              mealID: termMeal.id,
              order: Number(order),
              ...(mealSwap && { mealSwap }),
            }
          }
        }
      })
      .filter((menuMeal): menuMeal is TPSOTMenuMeal => !!menuMeal)

    const menuMealSwaps = getMenuMealSwaps(coreMeals)
    const menuSideSwaps: Record<number, number[]> = {}

    menuMealSwaps.forEach((mealSwap) => {
      menuSideSwaps[mealSwap.mealID] = mealSwap.swapIDs
    })

    if (!isEmpty(menuSideSwaps)) {
      sideSwaps[menu.id] = menuSideSwaps
    }
  })

  return sideSwaps
}

export const getMenuMainDisplayOrders = ({
  meals,
  term,
}: {
  meals: { chicago: TPOSTMeal[]; slc: TPOSTMeal[] }
  term: Term
}) => {
  const orderedMenus = flatMap(
    term.subTerms.map((subTerm) => subTerm.menus.map((menu) => menu)) ?? []
  ).sort((a, b) => a.name.localeCompare(b.name))

  const displayOrders: Record<string, TermMenuMeal[]> = {}
  orderedMenus.forEach((menu) => {
    const subTerm = term.subTerms.find(
      (subTerm) => subTerm.id === menu.subtermID
    )

    if (!subTerm) {
      return
    }

    const facilityNetworkMeals: TPOSTMeal[] = meals[subTerm.facilityNetwork]

    const coreMeals = facilityNetworkMeals
      .map((meal: TPOSTMeal) => {
        const menu = meal.menus.find((menu) => menu.name === 'Core - A')
        const termMeal = subTerm.defaultMenu.meals.find(
          (m) => +m.productionCode === meal.productionCode
        )

        if (menu && termMeal) {
          const [order, mealSwap] = menu.order.split('_')
          if (order && order !== '-' && termMeal.id) {
            return {
              mealID: termMeal.id,
              order: Number(order),
              ...(mealSwap && { mealSwap }),
            }
          }
        }
      })
      .filter((menuMeal): menuMeal is TPSOTMenuMeal => !!menuMeal)

    const orderedMeals = getOrderedMealIDs(coreMeals)
      .map((mealID) => {
        return menu.meals.find((m) => m.id === mealID)
      })
      .filter((meal): meal is TermMenuMeal => !!meal)

    if (orderedMeals.length > 0) {
      displayOrders[menu.id] = orderedMeals
    }
  })

  return displayOrders
}

const getMealData = (data: string[], menuNames: string[]) => {
  const mealIDString = data[TPSOT_COLUMN_INDEXES.mealID]
  const meal = {
    mealID: !Number.isNaN(Number(mealIDString))
      ? Number(mealIDString)
      : undefined,
    menus: data
      .filter((_column, index) => ALL_MENU_COLUMN_INDEXES.includes(index))
      .map((menuOrder, index) => {
        return {
          name: menuNames[index],
          order: menuOrder,
        }
      }),
    misevalaMealVersionID: data[TPSOT_COLUMN_INDEXES.misevalaMealVersionID],
    productionCode: Number.parseInt(
      data[TPSOT_COLUMN_INDEXES.productionCode],
      10
    ),
    type: data[TPSOT_COLUMN_INDEXES.type],
    workingTitle: data[TPSOT_COLUMN_INDEXES.workingTitle],
    version: data[TPSOT_COLUMN_INDEXES.version],
  }

  if (meal.workingTitle) {
    return meal
  }

  return
}

const getMealsToCreate = ({
  meals,
  term,
}: {
  meals: { chicago: TPOSTMeal[]; slc: TPOSTMeal[] }
  term: Term
}) => {
  const uniqueChicagoMeals: ImportedMealData[] = []
  const uniqueSLCMeals: ImportedMealData[] = []
  const otherMeals: ImportedMealData[] = []

  const chicagoMenus = term.subTerms
    .filter((subTerm) => subTerm.facilityNetwork === 'chicago')
    .map((subTerm) => {
      return subTerm.defaultMenu.id
    })

  const slcMenus = term.subTerms
    .filter((subTerm) => subTerm.facilityNetwork === 'slc')
    .map((subTerm) => {
      return subTerm.defaultMenu.id
    })

  meals.chicago.forEach((m) => {
    const matchingMeal = meals.slc.find(
      (meal) => meal.workingTitle === m.workingTitle
    )

    const meal: ImportedMealData = {
      ...m,
      expirationDate: '0001-01-01T00:00:00Z',
      facilityNetworks: ['CHI'],
      menuIDs: [...chicagoMenus],
      termid: term.id,
    }

    if (
      !matchingMeal ||
      (matchingMeal && matchingMeal.version !== meal.version)
    ) {
      uniqueChicagoMeals.push(meal)
    } else {
      meal.facilityNetworks.push('SLC')
      meal.menuIDs = [...meal.menuIDs, ...slcMenus]
      otherMeals.push(meal)
    }
  })

  meals.slc.forEach((m) => {
    const matchingMeal = meals.chicago.find(
      (meal) => meal.workingTitle === m.workingTitle
    )
    const meal: ImportedMealData = {
      ...m,
      expirationDate: '0001-01-01T00:00:00Z',
      facilityNetworks: ['SLC'],
      menuIDs: [...slcMenus],
      termid: term.id,
    }

    if (
      !matchingMeal ||
      (matchingMeal && matchingMeal.version !== meal.version)
    ) {
      uniqueSLCMeals.push(meal)
    }
  })

  const mealsToCreate = [
    ...otherMeals,
    ...uniqueChicagoMeals,
    ...uniqueSLCMeals,
  ].sort((a, b) => a.productionCode - b.productionCode)

  return mealsToCreate
}

export function getMenuMealSwaps(meals: TPSOTMenuMeal[]) {
  const mealSwaps: MealSwap[] = []

  const mealsWithSwaps = meals.filter((meal) => {
    if ('mealSwap' in meal) {
      return meal
    }
  })

  const [primaryMeals, swapMeals] = partition(
    mealsWithSwaps,
    (meal) => meal.mealSwap === 'A'
  )

  primaryMeals.forEach((meal) => {
    const matchingSwapMeals = swapMeals.filter(
      (swap) => swap.order === meal.order
    )

    mealSwaps.push({
      mealID: meal.mealID,
      swapIDs: matchingSwapMeals.map((swap) => swap.mealID),
    })
  })

  return mealSwaps
}

export function getOrderedMealIDs(meals: TPSOTMenuMeal[]) {
  return [...meals.sort((a, b) => a.order - b.order)].map((meal) => meal.mealID)
}

export async function getTPSOTMeals({
  file,
  term,
}: {
  file: File
  term: Term
}) {
  const data = await parseTPSOTMeals(file)

  const { meals } = data

  return {
    mealsToCreate: getMealsToCreate({ meals, term }),
    tpsotMeals: meals,
  }
}

export async function getTPSOTMenus({
  file,
  orderedSubTerms,
}: {
  file: File
  orderedSubTerms: TermSubTerm[]
}) {
  const data = await parseTPSOTMeals(file)

  const { meals, menus } = data

  const tpsotMenus: TPSOTMenus = {}

  orderedSubTerms.forEach((subTerm) => {
    const facilityNetworkMeals: TPOSTMeal[] = meals[subTerm.facilityNetwork]
    const facilityNetworkMenus = menus
      .map((menuName) => {
        const menuMeals = facilityNetworkMeals
          .filter(
            (meal) => !meal.workingTitle.toLowerCase().includes('extra sot')
          )
          .map((meal: TPOSTMeal) => {
            const menu = meal.menus.find((menu) => menu.name === menuName)
            if (menu) {
              const [order, mealSwap] = menu.order.split('_')
              if (order && order !== '-') {
                return {
                  mealID: meal.mealID,
                  order: Number(order),
                  ...(mealSwap && { mealSwap }),
                }
              }
            }
          })
          .filter((menuMeal): menuMeal is TPSOTMenuMeal => !!menuMeal)

        return {
          name: menuName,
          facilityNetwork: subTerm.facilityNetwork,
          mealIDs: getOrderedMealIDs(menuMeals),
          mealSwaps: getMenuMealSwaps(menuMeals),
          userAspects: TPSOT_COLUMN_USER_ASPECT_MAP[menuName.toLowerCase()],
        }
      })
      .filter((menu) => {
        return menu.mealIDs.length
      })

    const useDefaultMenu = facilityNetworkMenus.length === 1

    if (useDefaultMenu) {
      tpsotMenus[subTerm.id] = {
        subTermID: subTerm.id,
        description: `${FACILITY_NETWORKS[subTerm.facilityNetwork]} ${
          subTerm.shipPeriod
        } - Core`,
        errors: validateTPSOTMenuData({
          menu: facilityNetworkMenus[0],
          subTerm,
        }),
        mealIDs: facilityNetworkMenus[0].mealIDs,
        mealSwaps: facilityNetworkMenus[0].mealSwaps,
      }
    } else {
      const partitionMethod = facilityNetworkMenus.some(
        (variant) => variant.userAspects !== null
      )
        ? 'UserAspectMatching'
        : 'ModuloUserID'

      // The "Core - A" menu should always come last
      // TODO set this up as a priority look up object?
      const variantSortOrder =
        partitionMethod === 'ModuloUserID'
          ? ['Core - B', 'Core - A']
          : ['Thrive - A', 'Core - A']

      // TODO type this better
      const variants = variantSortOrder
        .map((name) => {
          return facilityNetworkMenus.find((menu) => menu.name === name)
        })
        .filter(
          (
            variant
          ): variant is {
            name: string
            facilityNetwork: string
            mealIDs: number[]
            mealSwaps: MealSwap[]
            userAspects: MenuVariantUserAspects[]
          } => !!variant
        )

      tpsotMenus[subTerm.id] = {
        subTermID: subTerm.id,
        variants: variants.map((variant) => {
          const name =
            partitionMethod === 'UserAspectMatching'
              ? variant.name.split(' - ')[0]
              : variant.name
          return {
            description: `${FACILITY_NETWORKS[subTerm.facilityNetwork]} ${
              subTerm.shipPeriod
            } - ${name}`,
            errors: validateTPSOTMenuData({ menu: variant, subTerm }),
            subTermID: subTerm.id,
            mealIDs: variant.mealIDs,
            mealSwaps: variant.mealSwaps,
            userAspects: variant.userAspects,
          }
        }),
      }
    }
  })

  return tpsotMenus
}

export async function parseTPSOTMeals(file: File): Promise<{
  meals: { chicago: TPOSTMeal[]; slc: TPOSTMeal[] }
  menus: string[]
}> {
  return new Promise((resolve) => {
    parse(file, {
      skipEmptyLines: false,
      complete: (results: ParseResult<string[]>) => {
        const chicago = results.data.slice(
          TPSOT_MEAL_ROW_INDEXES.chicago.start,
          TPSOT_MEAL_ROW_INDEXES.chicago.end + 1
        )
        const slc = results.data.slice(
          TPSOT_MEAL_ROW_INDEXES.slc.start,
          TPSOT_MEAL_ROW_INDEXES.slc.end + 1
        )

        const menus = results.data[TPSOT_HEADER_ROW_INDEX].filter(
          (_column, index) => ALL_MENU_COLUMN_INDEXES.includes(index)
        )

        const meals = {
          chicago: chicago
            .map((data) => getMealData(data, menus))
            .filter((meal): meal is TPOSTMeal => !!meal),
          slc: slc
            .map((data) => getMealData(data, menus))
            .filter((meal): meal is TPOSTMeal => !!meal),
        }

        resolve({ meals, menus })
      },
    })
  })
}

export function validateTPSOTMenuData({
  menu,
  subTerm,
}: {
  menu: {
    mealIDs: number[]
    mealSwaps: MealSwap[]
  }
  subTerm: TermSubTerm
}) {
  const errors: string[] = []

  const subTermMealIDs = subTerm.defaultMenu.meals.map((meal) => meal.id)

  if (menu.mealIDs.some((mealID) => mealID === 0)) {
    errors.push('One or more meals are missing meal IDs')
  }

  menu.mealIDs
    .filter((mealID) => mealID !== 0)
    .forEach((mealID) => {
      if (!subTermMealIDs.includes(mealID)) {
        errors.push(`Meal ID ${mealID} is not available on this subterm`)
      }
    })

  return errors
}
