import { useFormikContext } from 'formik'
import { useTovalaProducts } from '@tovala/browser-apis-combinedapi'

import { getAvailableProductsToBuy } from 'utils/products'

import Button from 'components/common/Button'
import InputField from '../common/InputField'
import SelectInput from '../common/SelectInput'

export interface FormData {
  code: string
  credit_amount: number | null
  description: string
  dollar_amount: number | null
  dollar_percent: number | null
  max_redemptions: string
  notes: string
  redeem_by: string
  sku: string
  type: string
  userid: string
}

const CouponCodeForm = ({
  readOnly = false,
}: {
  readOnly?: boolean
}): JSX.Element => {
  const { data: tovalaProducts = [] } = useTovalaProducts()

  const dollarOptions: number[] = []
  let dollars = 5
  const uniquePromoDollar = 149
  const independenceDayPromo = 139

  while (dollars <= 250) {
    dollarOptions.push(dollars)
    if (dollars < 100) {
      dollars += 5
    } else {
      dollars += 10
    }
  }

  // temporary $99 option, at end
  dollarOptions.push(uniquePromoDollar)
  dollarOptions.push(independenceDayPromo)
  dollarOptions.push(199)

  const percentOptions: number[] = []
  let percentage = 5
  while (percentage <= 100) {
    percentOptions.push(percentage)
    percentage += 5
  }

  const creditOptions: number[] = []
  let credit = 5
  while (credit <= 120) {
    creditOptions.push(credit)
    credit += 5
  }

  const couponTypes = [
    {
      label: 'Dollar Percent',
      value: 'dollar_percent',
    },
    {
      label: 'Dollar Amount',
      value: 'dollar_amount',
    },
    {
      label: 'Credit Amount',
      value: 'credit_amount',
    },
    {
      label: 'Dollar Amount + Credit Amount',
      value: 'dollar_and_credit',
    },
  ]

  const { values, setFieldValue } = useFormikContext<FormData>()

  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <InputField
            disabled={readOnly}
            label="Description"
            name="description"
            validate={(value) =>
              validateField(value, 'Please enter a description.')
            }
          />
        </div>

        <div className="space-y-1">
          <SelectInput
            afterChange={() => {
              setFieldValue('dollar_percent', '')
              setFieldValue('dollar_amount', '')
              setFieldValue('credit_amount', '')
            }}
            label="Type"
            name="type"
            options={couponTypes}
            readOnly={readOnly}
            validate={(value) =>
              validateField(value, 'Please select a coupon type.')
            }
          />

          <div className="space-y-4">
            {(values.type === 'dollar_amount' ||
              values.type === 'dollar_and_credit') && (
              <SelectInput
                label="Dollar Amount"
                name="dollar_amount"
                options={dollarOptions.map((option) => ({
                  label: `$${option}`,
                  value: option,
                }))}
                readOnly={readOnly}
                validate={(value) =>
                  validateField(value, 'Please select a dollar amount.')
                }
              />
            )}

            {values.type === 'dollar_percent' && (
              <SelectInput
                label="Dollar Percent"
                name="dollar_percent"
                options={percentOptions.map((option) => ({
                  label: `${option}%`,
                  value: option,
                }))}
                readOnly={readOnly}
                validate={(value) =>
                  validateField(value, 'Please select a dollar percentage.')
                }
              />
            )}

            {(values.type === 'credit_amount' ||
              values.type === 'dollar_and_credit') && (
              <SelectInput
                label="Credit Amount"
                name="credit_amount"
                options={creditOptions.map((option) => ({
                  label: `$${option}`,
                  value: option * 100,
                }))}
                readOnly={readOnly}
                validate={(value) =>
                  validateField(value, 'Please select a credit amount.')
                }
              />
            )}
          </div>
          <p className="text-sm">
            Dollar Amount is used for oven purchases. Credit Amount is used for
            meal purchases.
          </p>
        </div>

        <div className="space-y-1">
          <InputField
            disabled={readOnly}
            label="Redeem By"
            name="redeem_by"
            type="datetime-local"
          />
          <p className="text-sm">Expiration date will be set in local time.</p>
        </div>

        <div className="space-y-1">
          <InputField
            disabled={readOnly}
            label="Maximum Redemptions"
            min={0}
            name="max_redemptions"
            type="number"
          />
          <p className="text-sm">
            Max number of times this coupon can be redeemed (once per user
            recommended).
          </p>
        </div>

        <div className="space-y-1">
          <InputField
            disabled={readOnly}
            label="User ID"
            name="userid"
            type="number"
          />
          <p className="text-sm">Limit coupon redemption to specified user.</p>
        </div>

        <div className="space-y-1">
          <SelectInput
            label="Product"
            name="sku"
            options={getAvailableProductsToBuy(tovalaProducts)
              .filter((product) => {
                // filter out referral product skus
                return !(
                  product.chargeSKU ===
                    'tovala_oven_air_5in1_gray_149_commit_6_weeks_999shipping_msrp_299' ||
                  product.chargeSKU ===
                    'tovala_oven_steam_6in1_199_commit_6_weeks_999_shipping_msrp_349'
                )
              })
              .map((product) => ({
                label: product.description,
                value: product.chargeSKU,
              }))}
            readOnly={readOnly}
          />
          <p className="text-sm">
            Limit coupon redemption to specified product. Leave blank for coupon
            to apply to all products.
          </p>
        </div>

        <div className="col-span-2">
          <InputField disabled={readOnly} label="Notes" name="notes" />
        </div>
      </div>

      {!readOnly && (
        <div className="mt-8 h-10">
          <Button size="fluid" type="submit">
            Save
          </Button>
        </div>
      )}
    </div>
  )
}

export default CouponCodeForm

export const CodeField = ({
  readOnly,
}: {
  readOnly?: boolean
}): JSX.Element => {
  const { setFieldValue } = useFormikContext<FormData>()

  return (
    <InputField
      label="Code"
      name="code"
      onChange={(e) => {
        const { value } = e.target
        setFieldValue('code', value.toLowerCase())
      }}
      readOnly={readOnly}
      validate={(value) => validateField(value, 'Please enter a code.')}
    />
  )
}

export const validateField = (value: string, message?: string) => {
  let error: string | undefined
  if (!value) {
    error = message ? message : 'Required'
  }
  return error
}
