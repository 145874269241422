import { clsx } from 'clsx'
import {
  CouponAmount,
  ProductPurchase,
  useReleaseCommitment,
  useShipments,
  UserV1,
  UserV1Shipment,
  useResetReferralCouponCode,
} from '@tovala/browser-apis-combinedapi'
import { FormEvent, ReactNode, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { orderBy } from 'lodash-es'

import {
  applyDiscountPostPurchase,
  getCouponAmount,
  getProductsPurchaseInformation,
  refundOvenPurchase,
  refundPurchase,
} from '../../actions/auth'
import {
  CS_ADMIN,
  getAdminScope,
  ORDER_OVERRIDE,
  PRODUCTS_READ,
} from '../../utils/getAdminScope'
import { errorHandler, successHandler } from 'actions/notifications'
import { getAllCouponCodes } from '../../actions/marketing'
import { getAvailableProductsToReleaseCommitment } from 'utils/products'
import { ovenExchangeReasons } from './reasons'
import { parseToDate } from 'utils/dates'

import { useAppDispatch, useAppSelector } from 'hooks'
import { useGetTovalaProducts } from 'hooks/combinedAPI/products'
import Button from 'components/common/Button'
import ButtonLoading from 'components/common/ButtonLoading'
import FormLabel from 'components/common/FormLabel'
import H4 from 'components/common/H4'
import Hr from 'components/common/Hr'
import Input from 'components/common/Input'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'
import OverviewLine from './OverviewLine'
import Select from 'components/common/Select'
import SendExchangeOvenModal from './SendExchangeOvenModal'
import StatusDot from 'components/common/StatusDot'
import Subheading from './Subheading'
import { getEnvVar } from 'utils/env'

type PaymentLineItem = ProductPurchase['payment']['payment_line_item'][number]

interface PostPurchaseDiscountNotes {
  amount: number
  couponCode: string
  couponDescription?: string
  created: string
  notes: string
}

interface PurchaseStatus {
  canReturn: boolean | ''
  returnMessage: string | null
  trialDays: number | null
  underWarranty: boolean
  warrantyLastDay: string
  warrantyLength: string
}

const PRODUCT_ID_TOVALA_IQ_STANDALONE_OVEN_BACK_ORDER = getEnvVar(
  'TOVALA_IQ_STANDALONE_OVEN_BACK_ORDER'
)
const PRODUCT_ID_TOVALA_IQ_WEEKS_COMMMITMENT_BACK_ORDER = getEnvVar(
  'TOVALA_IQ_WEEKS_COMMMITMENT_BACK_ORDER'
)

function getProductName(sku: string, description: string) {
  let productName = ''

  if (
    sku === 'tovala_oven_299_3' ||
    sku === 'tovala_oven_299_0' ||
    sku === 'tovala_oven_299'
  ) {
    productName = '$299 Oven'
  } else if (
    sku === 'tovala_oven_199_commit_100' ||
    sku === 'tovala_oven_199'
  ) {
    productName = '$199 Oven'
  } else if (sku === 'tovala_oven_399') {
    productName = '$399 Oven'
  } else if (sku === 'trial_test_charge') {
    productName = 'Trial Oven'
  } else {
    productName = description
  }

  return productName
}

function getPurchaseStatus(
  purchaseDate: string,
  productPurchase: ProductPurchase
): PurchaseStatus {
  const today = moment()
  const endPurchaseDate180DayTrial = moment('2019-05-01 12:00')
  const { product } = productPurchase

  let trialDays: number | null = 100 // 100-day trial for standalone oven purchases
  const warrantyLength = `${product.warrantyUnit} ${product.warrantyPeriod}${
    product.warrantyUnit > 1 ? 's' : ''
  }`
  let canReturn: boolean | '' = ''
  let returnMessage: string | null = null

  // If purchased under original 180-day trial
  if (moment(purchaseDate).isBefore(endPurchaseDate180DayTrial)) {
    trialDays = 180
  }

  if (trialDays) {
    returnMessage = moment(purchaseDate)
      .add(trialDays, 'days')
      .format('MM/DD/YYYY')
    canReturn = moment(returnMessage).isSameOrAfter(today)
  }

  const warrantyLastDay = moment(productPurchase.warranty_ends).format(
    'MM/DD/YYYY'
  )
  const underWarranty = moment(warrantyLastDay).isSameOrAfter(today)

  return {
    returnMessage,
    canReturn,
    trialDays,
    underWarranty,
    warrantyLastDay,
    warrantyLength,
  }
}

const Shipment = ({
  index,
  purchaseStatus,
  shipment,
  shipments,
}: {
  index: number
  purchaseStatus?: PurchaseStatus | ''
  shipment: UserV1Shipment
  shipments: UserV1Shipment[]
}): JSX.Element => {
  const reason = ovenExchangeReasons.find(
    (reason) => reason.value === shipment.reason
  )
  let reasonLabel = shipment.reason
  if (reason && reason.label) {
    reasonLabel = reason.label
  }

  return (
    <div className="space-y-2 py-4">
      {shipments.length === 1 && (
        <span className="font-semibold">Shipment</span>
      )}
      {shipments.length > 1 && (
        <span className="font-semibold">
          {shipment.productType === 'exchange-oven'
            ? 'Exchange Shipment'
            : 'Original Shipment'}
        </span>
      )}
      <ShipmentLine label="Shipment Created">
        {moment(shipment.created).format('MM/DD/YYYY h:mm a')}
      </ShipmentLine>
      {shipment.shippingOrderID && (
        <ShipmentLine label="Order ID">{shipment.shippingOrderID}</ShipmentLine>
      )}
      {shipment.shippingAddress && (
        <ShipmentLine label="Shipping Address">
          {shipment.shippingAddress.split('\n').map((addr, i) => (
            <div key={i}>{addr}</div>
          ))}
        </ShipmentLine>
      )}
      {(shipment.trackingURL || shipment.trackingNumber) && (
        <ShipmentLine label="Tracking">
          {shipment.trackingURL ? (
            <a href={shipment.trackingURL} rel="noreferrer" target="_blank">
              {shipment.trackingURL}
            </a>
          ) : (
            <span>{shipment.trackingNumber}</span>
          )}
        </ShipmentLine>
      )}

      {shipment.shippingService && (
        <ShipmentLine label="Oven Parcel Carrier">
          {shipment.shippingService}
        </ShipmentLine>
      )}
      {shipment.fulfillmentService && (
        <ShipmentLine label="3PL Provider">
          {shipment.fulfillmentService}
        </ShipmentLine>
      )}
      {shipment.fulfillmentQueueStatus && (
        <ShipmentLine label="Fulfillment Queue Status">
          {shipment.fulfillmentQueueStatus}
        </ShipmentLine>
      )}
      {shipment.orderStatus && (
        <ShipmentLine label="Order Status">{shipment.orderStatus}</ShipmentLine>
      )}
      {shipment.reason && (
        <ShipmentLine label="Exchange Reason">{reasonLabel}</ShipmentLine>
      )}
      {shipment.notes && (
        <ShipmentLine label="Notes">{shipment.notes}</ShipmentLine>
      )}
      <ShipmentLine label="Product Shipment SKU">
        {shipment.shipmentSKU}
      </ShipmentLine>
      {index > 0 && purchaseStatus && (
        <ShipmentLine
          label={
            <div className="flex items-center space-x-1">
              <StatusDot
                color={purchaseStatus.underWarranty ? 'green' : 'red'}
              />
              <span>Warranty</span>
            </div>
          }
        >
          {purchaseStatus.warrantyLength} from original purchase (
          {purchaseStatus.warrantyLastDay})
        </ShipmentLine>
      )}
    </div>
  )
}

const ShipmentLine = ({
  children,
  label,
}: {
  children: ReactNode
  label: ReactNode
}) => {
  return (
    <div className="flex space-x-4">
      <div className="w-52 shrink-0">
        <Subheading>{label}</Subheading>
      </div>
      <div>{children}</div>
    </div>
  )
}

const DiscountModal = ({
  closeModal,
  couponApplied,
  orderIndex,
  productPurchases,
  user,
}: {
  closeModal(): void
  couponApplied: CouponAmount | ''
  orderIndex: number | ''
  productPurchases: ProductPurchase[]
  user: UserV1
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const [amount, setAmount] = useState('')
  const [coupon, setCoupon] = useState('')
  const [notes, setNotes] = useState('')
  const [showAmount, setShowAmount] = useState(false)

  const order = orderIndex === '' ? null : productPurchases[orderIndex]

  const verifyCoupon = () => {
    if (!order) {
      return
    }

    const product = order.product

    dispatch(
      getCouponAmount({
        couponCode: coupon,
        productid: product.id,
        userid: user.id,
      })
    )
  }

  const applyDiscount = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    let amountCents: number | '' = ''
    let couponCode = ''
    if (couponApplied && !amount) {
      amountCents = couponApplied.couponCode.dollarAmount * 100
      couponCode = couponApplied.couponCode.code
    } else if (amount) {
      amountCents = Number(amount) * 100
    }

    if (!order || amountCents === '') {
      return
    }

    dispatch(
      applyDiscountPostPurchase(
        user.id,
        order.id,
        couponCode,
        notes,
        amountCents
      )
    )

    setCoupon('')
    setAmount('')
    setNotes('')
    setShowAmount(false)

    closeModal()
  }

  let paymentDiscount: PaymentLineItem[] | '' = ''
  if (order && order.payment && order.payment.payment_line_item) {
    paymentDiscount = order.payment.payment_line_item.filter(
      (item) => item.type === 'discount'
    )
  }

  let totalDiscount: number | '' = ''
  if (paymentDiscount && paymentDiscount.length) {
    totalDiscount = paymentDiscount
      .map((discount) => discount.invoice_amount_cents)
      .reduce((total, item) => total + item)
  }

  return (
    <Modal onCloseModal={closeModal}>
      <ModalBody>
        <ModalHeader onClickClose={closeModal}>
          Apply Post Purchase Discount
        </ModalHeader>

        <div className="w-[500px] space-y-4">
          <div>
            <p className="font-bold">{user.info.name}</p>
            {user.info.email}
          </div>

          {order && (
            <>
              <div>
                <p className="font-bold">Product</p>
                <p>
                  {getProductName(
                    order.product.charge_sku,
                    order.product.description
                  )}
                </p>
              </div>
              <div>
                <p className="font-bold">Purchase Date</p>
                <p>
                  {moment(order.payment.created).format('MM/DD/YYYY h:mm a')}
                </p>
              </div>
            </>
          )}
          {!showAmount && (
            <div>
              <FormLabel>Coupon Code</FormLabel>
              <div className="flex space-x-4">
                <Input
                  onChange={(e) => {
                    setCoupon(e.target.value)
                  }}
                  type="text"
                  value={coupon}
                />
                <Button onClick={verifyCoupon} size="large">
                  Verify Coupon
                </Button>
              </div>
            </div>
          )}

          {showAmount && (
            <div>
              <FormLabel>Discount Amount in Dollars</FormLabel>
              <Input
                onChange={(e) => {
                  setAmount(e.target.value)
                }}
                type="number"
                value={amount}
              />
            </div>
          )}

          {((couponApplied &&
            couponApplied.couponCode &&
            couponApplied.couponCode.dollarAmount > 0) ||
            amount) && (
            <div>
              {couponApplied && couponApplied.couponCode && (
                <p className="mb-4">
                  <strong>
                    Coupon code {couponApplied.couponCode.code.toUpperCase()}{' '}
                    can be applied for a $
                    {couponApplied.couponCode.dollarAmount} discount.
                  </strong>
                </p>
              )}

              {couponApplied && totalDiscount && (
                <p className="mb-4">
                  Applying {couponApplied.couponCode.code.toUpperCase()} will
                  refund the user the difference between $
                  {couponApplied.couponCode.dollarAmount} and the previous
                  discount of ${(totalDiscount / 100) * -1} ($
                  {couponApplied.couponCode.dollarAmount + totalDiscount / 100}
                  ).
                </p>
              )}

              {amount && totalDiscount && (
                <p className="mb-4">
                  Applying a discount of ${amount} will refund the user the
                  difference between this discount and the previous discount of
                  ${(totalDiscount / 100) * -1} ($
                  {Number.parseInt(amount, 10) + totalDiscount / 100}).
                </p>
              )}

              <form className="space-y-4" onSubmit={applyDiscount}>
                <div>
                  <FormLabel>Notes</FormLabel>
                  <Input
                    onChange={(e) => {
                      setNotes(e.target.value)
                    }}
                    required
                    type="text"
                    value={notes}
                  />
                </div>

                <Button size="large" type="submit">
                  Apply Discount to Order
                </Button>
              </form>
            </div>
          )}
          {couponApplied && !couponApplied.valid && (
            <div>
              <p className="mb-4">
                <strong>
                  Coupon code {couponApplied.couponCode.code.toUpperCase()}{' '}
                  cannot be applied to this order.
                </strong>
              </p>
              <p className="mb-4">
                You can check that this coupon code exists and is not expired on
                the{' '}
                <Link
                  className="underline"
                  rel="noreferrer"
                  target="_blank"
                  to="/coupon-codes"
                >
                  Coupon Codes
                </Link>{' '}
                page.
              </p>
            </div>
          )}
          {couponApplied &&
            couponApplied.couponCode &&
            (couponApplied.couponCode.dollarPercent > 0 ||
              couponApplied.couponCode.mealsAmount > 0) && (
              <div>
                <p className="mb-4">
                  <strong>
                    Only dollar discount coupons can be applied in Glaze.
                  </strong>
                </p>
                <p className="mb-4">
                  This is a percentage discount or meal credit coupon. Please
                  make an unassigned DAT JIRA ticket to process this discount.
                </p>
              </div>
            )}
          <button
            className="mt-5"
            onClick={() => {
              setShowAmount((showAmount) => !showAmount)
              setAmount('')
            }}
          >
            Apply discount {!showAmount ? 'without' : 'with'} a coupon?
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}

const RefundModal = ({
  closeModal,
  orderIndex,
  productPurchases,
  user,
}: {
  closeModal(): void
  orderIndex: number | ''
  productPurchases: ProductPurchase[]
  user: UserV1
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const [notes, setNotes] = useState('')

  const order = orderIndex === '' ? null : productPurchases[orderIndex]

  const refundPurchase = (e: FormEvent<HTMLFormElement>) => {
    if (!order) {
      return
    }

    e.preventDefault()

    dispatch(refundOvenPurchase(user.id, order.id, 'refunded', false, notes))

    setNotes('')

    closeModal()
  }

  return (
    <Modal onCloseModal={closeModal}>
      <ModalBody>
        <ModalHeader onClickClose={closeModal}>Refund Order</ModalHeader>
        <div className="w-[500px] space-y-4">
          <div>
            <p className="font-bold">{user.info.name}</p>
            <p>{user.info.email}</p>
          </div>

          {order && (
            <>
              <div>
                <p className="font-bold">Product</p>
                <p>
                  {getProductName(
                    order.product.charge_sku,
                    order.product.description
                  )}
                </p>
              </div>
              <div>
                <p className="font-bold">Purchase Date</p>
                <p>
                  {moment(order.payment.created).format('MM/DD/YYYY h:mm a')}
                </p>
              </div>
            </>
          )}

          <form className="space-y-4" onSubmit={refundPurchase}>
            <div>
              <FormLabel>Notes</FormLabel>
              <Input
                onChange={(e) => {
                  setNotes(e.target.value)
                }}
                required
                type="text"
                value={notes}
              />
            </div>

            <Button size="large" type="submit">
              Refund Order
            </Button>
          </form>
        </div>
      </ModalBody>
    </Modal>
  )
}

const CancelModal = ({
  closeModal,
  orderIndex,
  productPurchases,
  needsFulfillmentCanceled,
  user,
}: {
  closeModal(): void
  orderIndex: number | ''
  productPurchases: ProductPurchase[]
  needsFulfillmentCanceled: boolean
  user: UserV1
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const [notes, setNotes] = useState('')
  const [fulfillmentCanceled, setFulfillmentCanceled] = useState(false)

  const order = orderIndex === '' ? null : productPurchases[orderIndex]

  const cancelPurchase = (e: FormEvent<HTMLFormElement>) => {
    if (!order) {
      return
    }

    e.preventDefault()

    let isBackOrderedProduct = false
    if (
      (order.product.id === PRODUCT_ID_TOVALA_IQ_STANDALONE_OVEN_BACK_ORDER ||
        order.product.id ===
          PRODUCT_ID_TOVALA_IQ_WEEKS_COMMMITMENT_BACK_ORDER) &&
      !order.oven_order_fulfillment.id
    ) {
      isBackOrderedProduct = true
    }

    if (!isBackOrderedProduct) {
      dispatch(
        refundOvenPurchase(
          user.id,
          order.id,
          'canceled',
          fulfillmentCanceled,
          notes
        )
      )
    } else {
      const data = {
        purchaseID: order.id,
        status: 'canceled',
        backOrder: true,
        notes,
      }

      dispatch(refundPurchase(user.id, data))
    }

    setNotes('')

    closeModal()
  }

  return (
    <Modal onCloseModal={closeModal}>
      <ModalBody>
        <ModalHeader onClickClose={closeModal}>Cancel Order</ModalHeader>
        <div className="w-[500px] space-y-4">
          <div>
            <p className="font-bold">{user.info.name}</p>
            <p>{user.info.email}</p>
          </div>

          {order && (
            <>
              <div>
                <p className="font-bold">Product</p>
                <p>
                  {getProductName(
                    order.product.charge_sku,
                    order.product.description
                  )}
                </p>
              </div>
              <div>
                <p className="font-bold">Purchase Date</p>
                <p>
                  {moment(order.payment.created).format('MM/DD/YYYY h:mm a')}
                </p>
              </div>
            </>
          )}
          {needsFulfillmentCanceled && (
            <div className="flex items-start">
              <FormLabel htmlFor="cancel-fulfillment">
                Fulfillment Canceled
              </FormLabel>
              <Input
                id="cancel-fulfillment"
                name="cancel-fulfillment"
                onChange={(e) => {
                  setFulfillmentCanceled(e.target.checked)
                }}
                type="checkbox"
              />
            </div>
          )}

          <form className="space-y-4" onSubmit={cancelPurchase}>
            <div>
              <FormLabel htmlFor="cancel-notes">Notes</FormLabel>
              <Input
                id="cancel-notes"
                name="cancel-notes"
                onChange={(e) => {
                  setNotes(e.target.value)
                }}
                required
                type="text"
                value={notes}
              />
            </div>

            <Button size="large" type="submit">
              Cancel Order
            </Button>
          </form>
        </div>
      </ModalBody>
    </Modal>
  )
}

const ReleaseCommitmentModal = ({
  closeModal,
  orderIndex,
  productPurchases,
  user,
}: {
  closeModal(): void
  orderIndex: number | ''
  productPurchases: ProductPurchase[]
  user: UserV1
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const [amount, setAmount] = useState('')
  const [cancelSubscription, setCancelSubscription] = useState(true)
  const [newProductID, setNewProductID] = useState('')
  const [notes, setNotes] = useState('')

  const order = orderIndex === '' ? null : productPurchases[orderIndex]

  const { data: availableTovalaProducts = [] } = useGetTovalaProducts({
    select: (products) => {
      // Allow CS agents to select from the current active standalone products.
      // See https://tovala.atlassian.net/browse/WAT-529 for more information.
      return getAvailableProductsToReleaseCommitment(products)
    },
  })

  // Convenience to auto-select the new product ID if there's only one option.
  useEffect(() => {
    if (availableTovalaProducts.length === 1) {
      setNewProductID(availableTovalaProducts[0].id)
    }
  }, [availableTovalaProducts])

  const { isLoading: isReleasingCommitment, mutate: releaseCommitment } =
    useReleaseCommitment({
      onError: (error) => {
        errorHandler(dispatch, error)
      },
      onSuccess: (_, { userID }) => {
        successHandler(dispatch, `Success! User released from commitment.`)
        dispatch(getProductsPurchaseInformation(userID))

        closeModal()
      },
    })

  return (
    <Modal onCloseModal={closeModal}>
      <ModalBody>
        <ModalHeader onClickClose={closeModal}>
          Release User from Commitment
        </ModalHeader>
        <div className="w-[500px] space-y-4">
          <div>
            <p className="font-bold">{user.info.name}</p>
            <p>{user.info.email}</p>
          </div>

          {order && (
            <>
              <div>
                <p className="font-bold">Product</p>
                <p>
                  {getProductName(
                    order.product.charge_sku,
                    order.product.description
                  )}
                </p>
              </div>
              <div>
                <p className="font-bold">Purchase Date</p>
                <p>
                  {moment(order.payment.created).format('MM/DD/YYYY h:mm a')}
                </p>
              </div>
            </>
          )}

          <form
            className="space-y-4"
            onSubmit={(event) => {
              if (!order) {
                return
              }

              event.preventDefault()

              releaseCommitment({
                data: {
                  amountCents: Number.parseInt(amount, 10) * 100,
                  cancelSubscription,
                  notes,
                  productID: newProductID,
                  purchaseID: order.id,
                },
                userID: user.id,
              })
            }}
          >
            <div>
              <FormLabel>New Product</FormLabel>
              <Select
                onChange={(e) => {
                  setNewProductID(e.target.value)
                }}
                required
                value={newProductID}
              >
                <option value=""></option>
                {availableTovalaProducts.map((product) => (
                  <option key={product.id} value={product.id}>
                    {product.description}
                  </option>
                ))}
              </Select>
            </div>

            <div>
              <FormLabel>Amount in Dollars</FormLabel>
              <Input
                onChange={(e) => {
                  setAmount(e.target.value)
                }}
                type="number"
                value={amount}
              />
            </div>

            <div className="flex items-center">
              <input
                checked={cancelSubscription}
                id="cancel-subscription"
                onChange={(e) => {
                  setCancelSubscription(e.target.checked)
                }}
                type="checkbox"
              />
              <label
                className="m-0 pl-2 text-sm uppercase tracking-widest"
                htmlFor="cancel-subscription"
              >
                Cancel Subscription
              </label>
            </div>

            <div>
              <FormLabel>Notes</FormLabel>
              <Input
                onChange={(e) => {
                  setNotes(e.target.value)
                }}
                required
                type="text"
                value={notes}
              />
            </div>

            {amount && (
              <p className="mt-2 font-bold">
                Charge user ${amount} (+ tax where applicable) to release
                commitment
                {cancelSubscription ? ' and cancel subscription' : ''}.
              </p>
            )}

            <ButtonLoading
              isLoading={isReleasingCommitment}
              size="large"
              type="submit"
            >
              Charge User
            </ButtonLoading>
          </form>
        </div>
      </ModalBody>
    </Modal>
  )
}

const ResetPromoCodeModal = ({
  closeModal,
  orderIndex,
  productPurchases,
  user,
}: {
  closeModal(): void
  orderIndex: number | ''
  productPurchases: ProductPurchase[]
  user: UserV1
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const order = orderIndex === '' ? null : productPurchases[orderIndex]
  const hasActiveCommitment = user.subscription.isCommitment
  const couponReferralTypeText = order?.oven_order_fulfillment.coupon_code
    ? 'Coupon'
    : 'Referral'

  const { isLoading: isResettingCode, mutate: resetReferralCouponCode } =
    useResetReferralCouponCode({
      onError: (error) => {
        errorHandler(dispatch, error)
      },
      onSuccess: (_, { userID }) => {
        successHandler(
          dispatch,
          `Success! ${couponReferralTypeText} Code has been reset for the user.`
        )
        dispatch(getProductsPurchaseInformation(userID))

        closeModal()
      },
    })

  return (
    <Modal onCloseModal={closeModal}>
      <ModalBody>
        <ModalHeader onClickClose={closeModal}>
          Reset {couponReferralTypeText} Code
        </ModalHeader>
        <div className="w-[500px] space-y-4">
          <div>
            <p className="font-bold">{user.info.name}</p>
            <p>{user.info.email}</p>
          </div>
          {hasActiveCommitment && (
            <div>
              <p>
                User has an active commitment. {couponReferralTypeText} Code
                cannot be reset until it is canceled.
              </p>
            </div>
          )}

          {order && !hasActiveCommitment && (
            <>
              <div>
                <p className="font-bold">Product</p>
                <p>
                  {getProductName(
                    order.product.charge_sku,
                    order.product.description
                  )}
                </p>
              </div>
              <div>
                <p className="font-bold">Purchase Date</p>
                <p>
                  {moment(order.payment.created).format('MM/DD/YYYY h:mm a')}
                </p>
              </div>
              {order.oven_order_fulfillment.coupon_code && (
                <div>
                  <p className="font-bold">Coupon Code</p>
                  <p className="uppercase">
                    {order.oven_order_fulfillment.coupon_code}
                  </p>
                </div>
              )}
              {order.oven_order_fulfillment.referral_code && (
                <div>
                  <p className="font-bold">Referral Code</p>
                  <p className="uppercase">
                    {order.oven_order_fulfillment.referral_code}
                  </p>
                </div>
              )}
            </>
          )}

          {order && !hasActiveCommitment && (
            <ButtonLoading
              isLoading={isResettingCode}
              onClick={() => {
                resetReferralCouponCode({
                  userID: user.id,
                  purchaseID: order.id,
                  data: {
                    couponCode: order.oven_order_fulfillment.coupon_code,
                    referralCode: order.oven_order_fulfillment.referral_code,
                  },
                })
              }}
              size="large"
              type="submit"
            >
              Reset {couponReferralTypeText} Code
            </ButtonLoading>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

const OvenOrderHistory = ({
  isAccountOverview,
  user,
}: {
  isAccountOverview: boolean
  user: UserV1
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const allCouponCodes = useAppSelector(
    (state) => state.marketing.allCouponCodes
  )
  const couponApplied = useAppSelector((state) => state.auth.couponApplied)
  const productPurchases = useAppSelector(
    (state) => state.auth.productPurchases
  )

  const [orderIndex, setOrderIndex] = useState<number | ''>('')
  const [needsFulfillmentCanceled, setNeedsFulfillmentCanceled] =
    useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showDiscountModal, setShowDiscountModal] = useState(false)
  const [showExchangeOvenModal, setShowExchangeOvenModal] = useState(false)
  const [showRefundModal, setShowRefundModal] = useState(false)
  const [showReleaseCommitmentModal, setShowReleaseCommitmentModal] =
    useState(false)
  const [showResetCodeModal, setShowResetCodeModal] = useState(false)

  const { data: shipments = [] } = useShipments({
    enabled: !isAccountOverview,
    userID: user.id,
  })

  const thirdPartyExchangeShipments = shipments.filter((shipment) => {
    return (
      shipment.productType === 'exchange-oven' &&
      shipment.productPurchaseID === null
    )
  })

  useEffect(() => {
    if (getAdminScope(PRODUCTS_READ) && !allCouponCodes.length) {
      dispatch(getAllCouponCodes())
    }
  }, [dispatch, allCouponCodes])

  function openDiscountModal(index: number) {
    setOrderIndex(index)
    setShowDiscountModal(true)
  }

  function openRefundModal(index: number) {
    setOrderIndex(index)
    setShowRefundModal(true)
  }

  function openCancelModal(index: number, needsFulfillmentCanceled: boolean) {
    setOrderIndex(index)
    setNeedsFulfillmentCanceled(needsFulfillmentCanceled)
    setShowCancelModal(true)
  }

  function openReleaseCommitmentModal(index: number) {
    setOrderIndex(index)
    setShowReleaseCommitmentModal(true)
  }

  const openExchangeOvenModal = (index: number | '') => {
    setOrderIndex(index)
    setShowExchangeOvenModal(true)
  }

  function openResetCodeModal(index: number) {
    setOrderIndex(index)
    setShowResetCodeModal(true)
  }

  const closeModal = () => {
    setShowDiscountModal(false)
    setShowRefundModal(false)
    setShowCancelModal(false)
    setShowReleaseCommitmentModal(false)
    setShowExchangeOvenModal(false)
    setShowResetCodeModal(false)
  }

  return (
    <div>
      {showDiscountModal && (
        <DiscountModal
          closeModal={closeModal}
          couponApplied={couponApplied}
          orderIndex={orderIndex}
          productPurchases={productPurchases}
          user={user}
        />
      )}
      {showRefundModal && (
        <RefundModal
          closeModal={closeModal}
          orderIndex={orderIndex}
          productPurchases={productPurchases}
          user={user}
        />
      )}
      {showCancelModal && (
        <CancelModal
          closeModal={closeModal}
          needsFulfillmentCanceled={needsFulfillmentCanceled}
          orderIndex={orderIndex}
          productPurchases={productPurchases}
          user={user}
        />
      )}
      {showReleaseCommitmentModal && (
        <ReleaseCommitmentModal
          closeModal={closeModal}
          orderIndex={orderIndex}
          productPurchases={productPurchases}
          user={user}
        />
      )}
      {showExchangeOvenModal && (
        <SendExchangeOvenModal
          closeModal={closeModal}
          orderIndex={orderIndex}
          user={user}
        />
      )}
      {showResetCodeModal && (
        <ResetPromoCodeModal
          closeModal={closeModal}
          orderIndex={orderIndex}
          productPurchases={productPurchases}
          user={user}
        />
      )}

      {productPurchases.length > 0 ? (
        <div>
          <div className="divide-y divide-grey-901">
            {productPurchases.map((productPurchase, index) => {
              let paymentTotal: PaymentLineItem[] | '' = ''
              let paymentCharge: PaymentLineItem[] | '' = ''
              let paymentSku: PaymentLineItem | '' | undefined = ''
              let paymentTax: PaymentLineItem | '' | undefined = ''
              let paymentDiscount: PaymentLineItem[] | '' = ''
              let paymentRefund: PaymentLineItem | '' | undefined = ''
              let giftCardTotal: PaymentLineItem | '' | undefined = ''
              let affirmTotal: number | '' = ''
              let postPurchaseDiscountNotes:
                | (PostPurchaseDiscountNotes | undefined)[]
                | '' = ''
              let mealsOnlyProduct = false
              let purchaseDate = ''
              let isBackOrderedProduct = false
              let couponReferralTypeText = ''
              let paymentHasCouponReferralCode = false

              const productPurchaseShipments = shipments.filter(
                ({ productPurchaseID }) => {
                  return productPurchaseID === productPurchase.id
                }
              )
              const originalShipment = productPurchaseShipments.find(
                ({ productType }) => {
                  return productType === 'tovala-oven'
                }
              )

              const needsFulfillmentCanceled =
                (originalShipment?.fulfillmentQueueStatus ===
                  'fulfillment-created' ||
                  originalShipment?.fulfillmentQueueStatus ===
                    'submitted-to-queue') &&
                originalShipment?.trackingNumber === '' &&
                originalShipment?.orderStatus === 'paid'

              if (
                productPurchase.product.charge_sku === 'meals_only_flow' ||
                productPurchase.product.charge_sku === 'amazon_oven_activate'
              ) {
                mealsOnlyProduct = true
                purchaseDate = productPurchase.product.updated
              }

              if (
                (productPurchase.product.id ===
                  PRODUCT_ID_TOVALA_IQ_STANDALONE_OVEN_BACK_ORDER ||
                  productPurchase.product.id ===
                    PRODUCT_ID_TOVALA_IQ_WEEKS_COMMMITMENT_BACK_ORDER) &&
                !productPurchase.oven_order_fulfillment.id
              ) {
                isBackOrderedProduct = true
              }

              if (productPurchase.payment.id) {
                paymentTotal = productPurchase.payment.payment_line_item.filter(
                  (item) => item.type === 'charge'
                )
                paymentCharge =
                  productPurchase.payment.payment_line_item.filter(
                    (item) => item.type === 'charge' && item.notes
                  )
                paymentSku = productPurchase.payment.payment_line_item.find(
                  (item) => item.type === 'sku'
                )
                paymentTax = productPurchase.payment.payment_line_item.find(
                  (item) => item.type === 'tax'
                )
                paymentDiscount =
                  productPurchase.payment.payment_line_item.filter(
                    (item) => item.type === 'discount'
                  )
                paymentRefund = productPurchase.payment.payment_line_item.find(
                  (item) => item.type === 'refund'
                )
                giftCardTotal = productPurchase.payment.payment_line_item
                  .filter((item) => item.type === 'gift_card')
                  .find((item) => item.transaction_amount_cents > 0)
                purchaseDate = productPurchase.payment.created
              } else if (productPurchase.affirm_charge.id) {
                affirmTotal = productPurchase.affirm_charge.amount
                purchaseDate = productPurchase.affirm_charge.created
              } else if (
                !productPurchase.payment.id &&
                productPurchase.oven_order_fulfillment.created
              ) {
                purchaseDate = productPurchase.oven_order_fulfillment.created
              }

              let couponDescription = ''
              if (
                productPurchase.oven_order_fulfillment.coupon_code &&
                allCouponCodes.length
              ) {
                const coupon = allCouponCodes.find(
                  (coupon) =>
                    coupon.code ===
                    productPurchase.oven_order_fulfillment.coupon_code
                )
                if (coupon) {
                  couponDescription = coupon.description
                }
                couponReferralTypeText = 'Coupon'
              }
              if (productPurchase.oven_order_fulfillment.referral_code) {
                couponReferralTypeText = 'Referral'
              }

              // If a discount line item has notes, it was added post-purchase
              // Also check if Discount Line has Referral or Coupon Code
              if (paymentDiscount !== '' && paymentDiscount.length) {
                postPurchaseDiscountNotes = paymentDiscount.map(
                  (discountLineItem) => {
                    if (
                      discountLineItem.coupon_code ||
                      discountLineItem.referral_code
                    ) {
                      paymentHasCouponReferralCode = true
                    }
                    if (discountLineItem.notes) {
                      const discount: PostPurchaseDiscountNotes = {
                        notes: discountLineItem.notes,
                        couponCode: discountLineItem.coupon_code,
                        created: discountLineItem.created,
                        amount: discountLineItem.invoice_amount_cents,
                      }

                      const coupon = allCouponCodes.find(
                        (coupon) => coupon.code === discountLineItem.coupon_code
                      )
                      if (coupon) {
                        discount.couponDescription = coupon.description
                      }
                      return discount
                    }
                  }
                )
              }

              let purchaseStatus: PurchaseStatus | '' = ''
              if (!mealsOnlyProduct) {
                purchaseStatus = getPurchaseStatus(
                  purchaseDate,
                  productPurchase
                )
              }

              return (
                <div key={productPurchase.id} className="flex space-x-4 p-5">
                  <div
                    className={clsx('space-y-2', {
                      'w-full': isAccountOverview,
                      'w-9/12': !isAccountOverview,
                    })}
                  >
                    {paymentRefund && (
                      <div className="space-y-2 border-t-8 border-yellow-903 bg-grey-902 p-3">
                        <div>
                          <div className="font-semibold">Order Refunded</div>
                          <span>
                            $
                            {(paymentRefund.invoice_amount_cents / 100).toFixed(
                              2
                            )}{' '}
                            refunded on{' '}
                            {moment(paymentRefund.created).format(
                              'MM/DD/YYYY [at] h:mm a'
                            )}
                          </span>
                        </div>
                        <div>
                          <Subheading>Notes</Subheading> {paymentRefund.notes}
                        </div>
                      </div>
                    )}

                    <OverviewLine label="Product">
                      {getProductName(
                        productPurchase.product.charge_sku,
                        productPurchase.product.description
                      )}
                    </OverviewLine>

                    <OverviewLine label="Purchase Date">
                      {moment(purchaseDate).format('MM/DD/YYYY h:mm a')}
                    </OverviewLine>

                    {/* Show commitment weeks if they haven't been converted to meals */}
                    {productPurchase.product.commitment_weeks > 0 &&
                      productPurchase.product.commitment_meals === 0 && (
                        <OverviewLine label="Commitment">
                          {productPurchase.product.commitment_weeks} weeks
                        </OverviewLine>
                      )}

                    {productPurchase.product.commitment_meals > 0 && (
                      <OverviewLine label="Commitment">
                        {productPurchase.product.commitment_meals} meals
                      </OverviewLine>
                    )}

                    <div>
                      {productPurchase.payment.id && (
                        <div>
                          {paymentTotal && paymentTotal.length > 0 && (
                            <OverviewLine label="Total Charge">
                              $
                              {(
                                paymentTotal
                                  .map((item) => item.transaction_amount_cents)
                                  .reduce((total, item) => total + item) / 100
                              ).toFixed(2)}
                            </OverviewLine>
                          )}

                          <div className="space-y-1">
                            {!isAccountOverview && paymentSku && (
                              <PaymentItem
                                cost={paymentSku.invoice_amount_cents}
                                label="Oven"
                              />
                            )}
                            {!isAccountOverview &&
                              paymentCharge &&
                              paymentCharge.length > 0 && (
                                <PaymentItem
                                  cost={paymentCharge
                                    .map(
                                      (item) => item.transaction_amount_cents
                                    )
                                    .reduce((total, item) => total + item)}
                                  label="Charge"
                                />
                              )}
                            {!isAccountOverview &&
                              paymentDiscount &&
                              paymentDiscount.map((discount) => {
                                return (
                                  <PaymentItem
                                    key={discount.id}
                                    cost={discount.invoice_amount_cents}
                                    label={`Discount${
                                      discount.notes ? '*' : ''
                                    }`}
                                  />
                                )
                              })}
                            {!isAccountOverview && paymentTax && (
                              <PaymentItem
                                cost={paymentTax.invoice_amount_cents}
                                label="Tax"
                              />
                            )}
                            {giftCardTotal && (
                              <PaymentItem
                                cost={giftCardTotal.transaction_amount_cents}
                                label="Gift Card"
                              />
                            )}
                          </div>
                        </div>
                      )}

                      {affirmTotal && (
                        <OverviewLine label="Affirm Total">
                          ${(affirmTotal / 100).toFixed(2)}
                        </OverviewLine>
                      )}
                    </div>

                    {productPurchase.oven_order_fulfillment.coupon_code && (
                      <OverviewLine label="Coupon Code">
                        <span className="uppercase">
                          {productPurchase.oven_order_fulfillment.coupon_code}
                        </span>
                      </OverviewLine>
                    )}

                    {productPurchase.oven_order_fulfillment.referral_code && (
                      <OverviewLine label="Referral Code">
                        <span className="uppercase">
                          {productPurchase.oven_order_fulfillment.referral_code}
                        </span>
                      </OverviewLine>
                    )}

                    {couponDescription && (
                      <OverviewLine label="Coupon Type">
                        {couponDescription}
                      </OverviewLine>
                    )}

                    {postPurchaseDiscountNotes &&
                      postPurchaseDiscountNotes.map((discount) => {
                        if (discount) {
                          return (
                            <div key={discount.couponCode}>
                              <OverviewLine label="Post Purchase Discount">
                                ${discount.amount / 100} (
                                {discount.couponCode
                                  ? `${discount.couponCode.toUpperCase()} `
                                  : ''}
                                applied{' '}
                                {moment(discount.created).format(
                                  'MM/DD/YYYY h:mm a'
                                )}
                                )
                              </OverviewLine>
                              <div>
                                <Subheading>Notes</Subheading>
                                {discount.notes}
                                <p className="mt-1 italic">
                                  * Post purchase discount is not reflected in
                                  the Total Charge amount above
                                </p>
                              </div>
                            </div>
                          )
                        }
                      })}

                    {!paymentRefund &&
                      purchaseStatus &&
                      productPurchase.product.create_fulfillment && (
                        <>
                          <OverviewLine
                            label={
                              <div className="flex items-center space-x-1">
                                <StatusDot
                                  color={
                                    purchaseStatus.canReturn ? 'green' : 'red'
                                  }
                                />
                                <span>Return</span>
                              </div>
                            }
                          >
                            {purchaseStatus.trialDays ? (
                              <span>
                                {purchaseStatus.trialDays} days (
                                {purchaseStatus.returnMessage})
                              </span>
                            ) : (
                              <span>{purchaseStatus.returnMessage}</span>
                            )}
                          </OverviewLine>

                          <OverviewLine
                            label={
                              <div className="flex items-center space-x-1">
                                <StatusDot
                                  color={
                                    purchaseStatus.underWarranty
                                      ? 'green'
                                      : 'red'
                                  }
                                />
                                <span>Warranty</span>
                              </div>
                            }
                          >
                            <span>
                              {purchaseStatus.warrantyLength} (
                              {purchaseStatus.warrantyLastDay})
                            </span>
                          </OverviewLine>
                        </>
                      )}

                    {!isAccountOverview && productPurchase.payment.notes && (
                      <div>
                        <div className="font-semibold">Notes</div>
                        {productPurchase.payment.notes}
                      </div>
                    )}

                    {!isAccountOverview &&
                      getOrderedShipments(productPurchaseShipments).map(
                        (shipment, index) => {
                          return (
                            <Shipment
                              key={index}
                              index={index}
                              purchaseStatus={purchaseStatus}
                              shipment={shipment}
                              shipments={productPurchaseShipments}
                            />
                          )
                        }
                      )}
                  </div>
                  {!isAccountOverview && !mealsOnlyProduct && (
                    <div className="w-3/12 space-y-2">
                      {!paymentRefund && (
                        <>
                          {getAdminScope(ORDER_OVERRIDE) && (
                            <>
                              {!affirmTotal && (
                                <div className="h-10">
                                  <Button
                                    buttonStyle="grey"
                                    onClick={() => openDiscountModal(index)}
                                    size="fluid"
                                  >
                                    Apply Discount
                                  </Button>
                                </div>
                              )}
                              {!isBackOrderedProduct &&
                                getAdminScope('CS_ADMIN') && (
                                  <div className="h-10">
                                    <Button
                                      buttonStyle="grey"
                                      onClick={() => openRefundModal(index)}
                                      size="fluid"
                                    >
                                      Refund Order
                                    </Button>
                                  </div>
                                )}
                              {/*
                               * Creating a shipment for an order is an async process and some
                               * orders don't have shipments (e.g. Amazon orders that are not
                               * fulfilled by us). It doesn't make sense to cancel an oven order
                               * that does not have a shipment.
                               */}
                              {(originalShipment?.canCancel ||
                                needsFulfillmentCanceled) && (
                                <div className="h-10">
                                  <Button
                                    buttonStyle="grey"
                                    onClick={() =>
                                      openCancelModal(
                                        index,
                                        needsFulfillmentCanceled
                                      )
                                    }
                                    size="fluid"
                                  >
                                    Cancel {isBackOrderedProduct ? 'Back' : ''}{' '}
                                    Order
                                  </Button>
                                </div>
                              )}
                              {(productPurchase.product.commitment_weeks > 0 ||
                                productPurchase.product.commitment_meals >
                                  0) && (
                                <div className="h-10">
                                  <Button
                                    buttonStyle="grey"
                                    onClick={() =>
                                      openReleaseCommitmentModal(index)
                                    }
                                    size="fluid"
                                  >
                                    Release Commitment
                                  </Button>
                                </div>
                              )}
                            </>
                          )}
                          {getAdminScope(CS_ADMIN) && (
                            <div className="h-10">
                              <Button
                                buttonStyle="grey"
                                onClick={() => openExchangeOvenModal(index)}
                                size="fluid"
                              >
                                Send Exchange Oven
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                      {getAdminScope(ORDER_OVERRIDE) &&
                        paymentHasCouponReferralCode && (
                          <div className="h-10">
                            <Button
                              buttonStyle="grey"
                              onClick={() => openResetCodeModal(index)}
                              size="fluid"
                            >
                              Reset {couponReferralTypeText} Code
                            </Button>
                          </div>
                        )}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        <div>
          <p className="mb-4">No order history found.</p>
        </div>
      )}

      {getAdminScope(CS_ADMIN) && !isAccountOverview && (
        <div>
          <Hr />
          <div className="flex items-center space-x-4 bg-grey-904 p-5">
            <H4>Need to send an exchange oven for a third-party purchase?</H4>
            <Button
              buttonStyle="grey"
              onClick={() => openExchangeOvenModal('')}
              size="large"
            >
              Send Exchange Oven
            </Button>
          </div>
        </div>
      )}

      {!isAccountOverview && thirdPartyExchangeShipments.length > 0 && (
        <div className="mt-4">
          <H4>Third-party Exchange Shipments</H4>
          <div className="divide-y divide-grey-900">
            {getOrderedShipments(thirdPartyExchangeShipments).map(
              (shipment, index) => {
                return (
                  <Shipment
                    key={shipment.id}
                    index={index}
                    shipment={shipment}
                    shipments={shipments}
                  />
                )
              }
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default OvenOrderHistory

const PaymentItem = ({ cost, label }: { cost: number; label: string }) => {
  return (
    <div className="flex items-center">
      <div className="w-56">
        <Subheading>{label}</Subheading>
      </div>
      <div className="text-sm">${(cost / 100).toFixed(2)}</div>
    </div>
  )
}

export function getOrderedShipments(shipments: UserV1Shipment[]) {
  return orderBy(shipments, ({ created }) => parseToDate(created), 'desc')
}
