import { clsx } from 'clsx'
import { Field, Form, Formik, useField } from 'formik'
import {
  ListingsReceipt,
  OrderHistoryReceipt,
  RemainingOrderBalance,
  UserV1,
  useInvalidateMealCash,
  useInvalidateReceipts,
} from '@tovala/browser-apis-combinedapi'
import ReactSelect from 'react-select'
import { useState } from 'react'

import { getUserId } from 'utils/getUserId'
import { MEAL_CASH_REFUND_REASON_OPTIONS } from 'utils/dropdowns'
import {
  getUserInfo,
  partialRefundOrder,
  refundOrder,
} from '../../actions/auth'
import {
  partialRefundOrderWithMealCash,
  refundOrderWithMealCash,
  refundShippingToMealCash,
} from '../../actions/user'
import { ReactSelectOptionGroup, ReactSelectValue } from 'types/internal'
import { showSuccessNotification } from '../../actions/notifications'

import { RefundFormData, useAddMarketingCreditsSubmit } from './hooks'
import { useAppDispatch } from 'hooks'
import Button from 'components/common/Button'
import FormLabel from 'components/common/FormLabel'
import { INPUT_CLASSES } from 'components/common/Input'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'
import { TEXTAREA_CLASSES } from 'components/common/Textarea'

interface RefundFormDataValidated extends RefundFormData {
  reason: ReactSelectValue<string>
}

const RefundOrderModal = ({
  listingsReceipt,
  onCloseModal,
  order,
  paymentInvoice,
  type,
  user,
}: {
  listingsReceipt: ListingsReceipt | undefined
  onCloseModal(): void
  order: OrderHistoryReceipt
  paymentInvoice: RemainingOrderBalance['paymentInvoice'] | null
  type: 'credit' | 'refund'
  user: UserV1
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const isEmployeeOrder = user.info.isEmployee
  const isNoChargeOrder = order.paymentStatus === ''

  const [submitDisabled, setSubmitDisabled] = useState(false)

  const { invalidateMealCashHistory } = useInvalidateMealCash()
  const { invalidateOrderHistoryReceipts } = useInvalidateReceipts()

  const { addMarketingCredits } = useAddMarketingCreditsSubmit({
    onSuccess: onCloseModal,
    userid: user.id,
  })

  function validateField(value: string | ReactSelectValue<string> | null) {
    let error: string | undefined
    if (!value) {
      error = 'Required'
    }
    return error
  }

  const handleRefundSubmit = (values: RefundFormDataValidated) => {
    if (submitDisabled) {
      return
    }

    setSubmitDisabled(true)

    const data: {
      action?: string
      affectedListingIDs?: string[]
      affectedMealIDs?: number[]
      csAgent: string
      notes: string
      numberAffectedMeals?: number
      reason: string
      zdTicketNumber?: string
      zendeskTicket?: string
    } = {
      notes: values.notes,
      reason: values.reason.value,
      csAgent: getUserId()?.toString() ?? '',
    }

    if (type === 'credit') {
      data.zendeskTicket = values.zdTicketNumber
    } else {
      data.action = 'customer_service'
      data.zdTicketNumber = values.zdTicketNumber
    }

    if (values.refundType === 'partial') {
      if (values.refundShipping) {
        dispatch(
          refundShippingToMealCash(user.id, order.userTermOrderID, data)
        ).then(() => {
          dispatch(getUserInfo(user.id))
        })
      }

      data.affectedMealIDs = values.affectedMealIDs.map((m) => {
        // Remove index
        const mealid = m.split('-')[0]
        return Number.parseInt(mealid, 10)
      })

      data.affectedListingIDs = values.affectedListingIDs.map(
        (listingSelection) => {
          // Remove index
          const listingID = listingSelection.split('|')[0]
          return listingID
        }
      )

      if (type === 'credit') {
        if (
          data.affectedMealIDs.length > 0 ||
          data.affectedListingIDs.length > 0
        ) {
          dispatch(
            partialRefundOrderWithMealCash(user.id, order.userTermOrderID, data)
          ).then((response) => {
            invalidateMealCashHistory(user.id)
            invalidateOrderHistoryReceipts(user.id)

            dispatch(getUserInfo(user.id))

            if (
              response &&
              response.payload &&
              values.additionalCredit === 'yes' &&
              values.amount
            ) {
              addMarketingCredits(values).then(() => {
                invalidateMealCashHistory(user.id)
                dispatch(getUserInfo(user.id))
              })
            }
          })
        }
      } else {
        data.numberAffectedMeals = data.affectedMealIDs.length

        dispatch(partialRefundOrder(user.id, order.userTermOrderID, data)).then(
          (response) => {
            invalidateOrderHistoryReceipts(user.id)

            if (
              response &&
              response.payload &&
              values.additionalCredit === 'yes' &&
              values.amount
            ) {
              addMarketingCredits(values).then(() => {
                invalidateMealCashHistory(user.id)
              })
            }
          }
        )
      }
    } else if (values.refundType === 'full') {
      if (type === 'credit') {
        dispatch(
          refundOrderWithMealCash(user.id, order.userTermOrderID, data)
        ).then((response) => {
          invalidateMealCashHistory(user.id)
          invalidateOrderHistoryReceipts(user.id)
          dispatch(getUserInfo(user.id))

          if (
            response &&
            response.payload &&
            values.additionalCredit === 'yes' &&
            values.amount
          ) {
            addMarketingCredits(values).then(() => {
              invalidateMealCashHistory(user.id)
              dispatch(getUserInfo(user.id))
            })
          }
        })
      } else {
        data.numberAffectedMeals = order.mealCount

        dispatch(
          refundOrder(user.id, order.userTermOrderID, 'refunded', data)
        ).then((response) => {
          invalidateOrderHistoryReceipts(user.id)

          if (
            response &&
            response.payload &&
            values.additionalCredit === 'yes' &&
            values.amount
          ) {
            addMarketingCredits(values).then(() => {
              invalidateMealCashHistory(user.id)
            })
          }
        })
      }
    } else if (values.refundType === 'shipping') {
      dispatch(
        refundShippingToMealCash(user.id, order.userTermOrderID, data)
      ).then((response) => {
        invalidateMealCashHistory(user.id)
        invalidateOrderHistoryReceipts(user.id)
        dispatch(getUserInfo(user.id))

        if (response && response.payload) {
          dispatch(showSuccessNotification('Success! Shipping charge refunded'))
        }
      })
    }

    onCloseModal()
  }

  const initialValues: RefundFormData = {
    refundType:
      order.paymentStatus === 'paid' || isNoChargeOrder ? 'full' : 'partial',
    affectedListingIDs: [],
    affectedMealIDs: [],
    reason: null,
    zdTicketNumber: '',
    notes: '',
    additionalCredit: 'no',
    amount: '',
    refundShipping: false,
  }

  const fullRefundAvailable = order.paymentStatus === 'paid' || isNoChargeOrder
  const partialRefundAvailable = !isNoChargeOrder
  const hasShippingCharge = order.paymentLineItems.some(
    (pli) => pli.type === 'shipping' && pli.invoiceAmountCents > 0
  )
  const shippingChargeIsRefunded =
    paymentInvoice && paymentInvoice.CashObjects
      ? paymentInvoice.CashObjects.some(
          (cashObject) => cashObject.action === 'shipping_refund'
        )
      : false
  const actionWord = type === 'credit' ? 'Credit' : 'Refund'
  const lowerCaseActionWord = actionWord.toLowerCase()

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>
          {type === 'credit'
            ? 'Credit Order to Tovala Cash balance'
            : 'Refund Order'}{' '}
          - Term #{order.termID}
        </ModalHeader>

        <div className="w-[800px]">
          {type === 'refund' && isNoChargeOrder ? (
            <p>
              This order was provided free of charge, no payment will be
              refunded. Refunding this order may allow the user to receive
              another order free of charge.
            </p>
          ) : type === 'refund' ? (
            <p>Refund will be applied to original payment method.</p>
          ) : (
            <p>Credit will be added to Tovala Cash balance.</p>
          )}

          {paymentInvoice && paymentInvoice.availableBalanceToRefund >= 0 && (
            <div className="text-lg">
              <span>Available Balance to {actionWord}: </span>
              <span>
                ${(paymentInvoice.availableBalanceToRefund / 100).toFixed(2)}
              </span>
            </div>
          )}

          <div className="my-4">
            <p className="font-bold">{user.info.name}</p>
            <p>{user.info.email}</p>
          </div>

          {(!paymentInvoice ||
            (paymentInvoice &&
              paymentInvoice.availableBalanceToRefund > 0)) && (
            <Formik<RefundFormData>
              initialValues={initialValues}
              onSubmit={(formData) => {
                return handleRefundSubmit(formData as RefundFormDataValidated)
              }}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ setFieldValue, values, errors }) => {
                // Use this array to disable meals that have been refunded from the partial refund list
                const refundedMealIDs = paymentInvoice
                  ? [...paymentInvoice.refundedMealIDs]
                  : []
                const refundedListingIDs = paymentInvoice
                  ? [...paymentInvoice.refundedListingIDs]
                  : []

                return (
                  <Form>
                    <div>
                      <div className="mt-4">
                        <p className="font-bold">
                          What should be {lowerCaseActionWord}ed?
                        </p>
                        {fullRefundAvailable && (
                          <label className="mr-4">
                            <Field
                              name="refundType"
                              type="radio"
                              value="full"
                            />
                            &nbsp; All meals (full {lowerCaseActionWord})
                          </label>
                        )}

                        {partialRefundAvailable && (
                          <label className="mr-4">
                            <Field
                              name="refundType"
                              type="radio"
                              value="partial"
                            />
                            &nbsp; Select meals to {lowerCaseActionWord}{' '}
                            (partial {lowerCaseActionWord})
                          </label>
                        )}

                        {hasShippingCharge &&
                          !shippingChargeIsRefunded &&
                          type === 'credit' && (
                            <label className="mr-4">
                              <Field
                                name="refundType"
                                type="radio"
                                value="shipping"
                              />
                              &nbsp; Shipping charge
                            </label>
                          )}
                      </div>

                      {values.refundType === 'partial' && (
                        <div className="mt-2 space-y-4">
                          <div>
                            <FormLabel>Meals to {actionWord}</FormLabel>

                            {paymentInvoice &&
                              paymentInvoice.refundedMealIDs.length > 0 && (
                                <p className="mb-4">
                                  <i>
                                    Unselectable meals have already been
                                    refunded.
                                  </i>
                                </p>
                              )}

                            <div className="space-y-1">
                              {order.selectedMeals.map((meal, index) => {
                                let hasBeenRefunded = false
                                const refundedMealIndex =
                                  refundedMealIDs.indexOf(meal.id)
                                if (refundedMealIndex !== -1) {
                                  hasBeenRefunded = true
                                  refundedMealIDs.splice(refundedMealIndex, 1)
                                }

                                return (
                                  <label
                                    key={`${meal.id}-${index}`}
                                    className={clsx('block w-full', {
                                      'opacity-50': hasBeenRefunded,
                                    })}
                                  >
                                    <Field
                                      checked={values.affectedMealIDs.includes(
                                        `${meal.id}-${index}`
                                      )}
                                      disabled={hasBeenRefunded}
                                      name="affectedMealIDs"
                                      type="checkbox"
                                      validate={(value: string[]) => {
                                        let error = ''
                                        if (
                                          value.length === 0 &&
                                          !values.refundShipping &&
                                          values.affectedListingIDs.length === 0
                                        ) {
                                          error =
                                            'Please select at least one affected meal or extra'
                                        }
                                        return error
                                      }}
                                      value={`${meal.id}-${index}`}
                                    />
                                    &nbsp;&nbsp;
                                    <strong>
                                      {meal.title} | $
                                      {(
                                        (meal.basePriceCents +
                                          meal.surchargeCents) /
                                        100
                                      ).toFixed(2)}
                                    </strong>
                                    <br />
                                    <span className="ml-6 text-sm">
                                      {meal.subtitle} (#{meal.id})
                                    </span>
                                  </label>
                                )
                              })}
                            </div>
                          </div>

                          <div>
                            <FormLabel>Extras to {actionWord}</FormLabel>

                            {paymentInvoice &&
                              paymentInvoice.refundedListingIDs.length > 0 && (
                                <p className="mb-4">
                                  <i>
                                    Unselectable extras have already been
                                    refunded.
                                  </i>
                                </p>
                              )}

                            <div className="space-y-1">
                              {listingsReceipt &&
                                listingsReceipt.listings.length > 0 && (
                                  <div className="space-y-1">
                                    {listingsReceipt.listings.map(
                                      (listing, index) => {
                                        const listingIdentifier = `${listing.id}|${index}`

                                        let hasBeenRefunded = false
                                        const refundedListingIndex =
                                          refundedListingIDs.indexOf(listing.id)
                                        if (refundedListingIndex !== -1) {
                                          hasBeenRefunded = true
                                          refundedListingIDs.splice(
                                            refundedListingIndex,
                                            1
                                          )
                                        }

                                        return (
                                          <label
                                            key={listingIdentifier}
                                            className={clsx('block w-full', {
                                              'opacity-50': hasBeenRefunded,
                                            })}
                                          >
                                            <Field
                                              checked={values.affectedListingIDs.includes(
                                                listingIdentifier
                                              )}
                                              disabled={hasBeenRefunded}
                                              name="affectedListingIDs"
                                              type="checkbox"
                                              validate={(value: string[]) => {
                                                let error = ''
                                                if (
                                                  value.length === 0 &&
                                                  !values.refundShipping &&
                                                  values.affectedMealIDs
                                                    .length === 0
                                                ) {
                                                  error =
                                                    'Please select at least one affected meal or extra'
                                                }
                                                return error
                                              }}
                                              value={listingIdentifier}
                                            />
                                            &nbsp;&nbsp;
                                            <strong>
                                              {listing.title} | $
                                              {(
                                                listing.priceCents / 100
                                              ).toFixed(2)}
                                            </strong>
                                          </label>
                                        )
                                      }
                                    )}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )}

                      {errors.affectedMealIDs && (
                        <div className="text-red-901">
                          {errors.affectedMealIDs}
                        </div>
                      )}

                      <br />
                      {type === 'credit' &&
                        values.refundType === 'partial' &&
                        hasShippingCharge &&
                        !shippingChargeIsRefunded && (
                          <div>
                            <Field
                              id="refundShipping"
                              name="refundShipping"
                              type="checkbox"
                            />
                            <label htmlFor="refundShipping">
                              &nbsp;Also {actionWord} Shipping charge
                            </label>
                          </div>
                        )}

                      <div className="space-y-4">
                        <div>
                          <FormLabel>Reason*</FormLabel>
                          <Select
                            name="reason"
                            onChange={(option) => {
                              setFieldValue('reason', option)
                            }}
                            options={MEAL_CASH_REFUND_REASON_OPTIONS}
                            validate={validateField}
                            value={values.reason}
                          />
                          {errors.reason && (
                            <div className="text-red-901">{errors.reason}</div>
                          )}
                        </div>

                        <div>
                          <FormLabel>ZD Ticket #</FormLabel>
                          <Field
                            className={INPUT_CLASSES}
                            name="zdTicketNumber"
                            placeholder="Enter ZD Ticket # if applicable"
                            type="text"
                          />
                        </div>

                        <div>
                          <FormLabel>Notes*</FormLabel>
                          <Field
                            as="textarea"
                            className={TEXTAREA_CLASSES}
                            name="notes"
                            rows={5}
                            validate={validateField}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      {!isEmployeeOrder &&
                        (values.affectedMealIDs.length > 0 ||
                          values.refundType === 'full') && (
                          <div>
                            <p className="mr-4 font-bold">
                              Also add Marketing Credits (Discount)?
                            </p>
                            <label className="mr-4">
                              <Field
                                name="additionalCredit"
                                type="radio"
                                value="no"
                              />
                              &nbsp; No
                            </label>
                            <label>
                              <Field
                                name="additionalCredit"
                                type="radio"
                                value="yes"
                              />
                              &nbsp; Yes
                            </label>
                          </div>
                        )}

                      {values.additionalCredit === 'yes' && (
                        <div>
                          <div className="my-4 space-y-2">
                            <p>
                              Add Marketing Credits to user&apos;s Discount
                              account balance in addition to the{' '}
                              {lowerCaseActionWord} above. These additional
                              Marketing Credits will appear as a separate row in
                              the user&apos;s Account Balance History and can be
                              removed from there if needed.
                            </p>
                            {isNoChargeOrder && (
                              <p className="font-bold">
                                NOTE: This order was provided free of charge to
                                the user. Are you sure you want to add marketing
                                credits? Check user tags, RTG, and user notes
                                for special guidance.
                              </p>
                            )}
                          </div>

                          <div>
                            <FormLabel>
                              Amount in Dollars ($1.00 - $100.00)*
                            </FormLabel>
                            <Field
                              className={INPUT_CLASSES}
                              max="100"
                              min="0"
                              name="amount"
                              step="0.01"
                              type="number"
                              validate={validateField}
                              value={values.amount}
                            />
                            {errors.amount && (
                              <div className="text-red-901">
                                {errors.amount}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mt-4">
                      <Button
                        disabled={submitDisabled}
                        size="large"
                        type="submit"
                      >
                        {submitDisabled ? (
                          'Submitting...'
                        ) : (
                          <span>
                            Submit&nbsp;
                            {values.refundType}
                            &nbsp;{actionWord}
                            {type === 'credit' && ' to Tovala Cash balance'}
                          </span>
                        )}
                      </Button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default RefundOrderModal

const Select = (props: {
  name: string
  options: ReactSelectOptionGroup<string>[]
  onChange(newOption: ReactSelectValue<string> | null): void
  validate(value: ReactSelectValue<string>): string | undefined
  value: ReactSelectValue<string> | null
}) => {
  const [field] = useField({ name: props.name, validate: props.validate })

  return <ReactSelect {...field} {...props} />
}
