import { Button, CheckIcon, XIcon } from '@tovala/component-library'
import { clsx } from 'clsx'
import { createPortal } from 'react-dom'
import { ReactNode } from 'react'

export type Props =
  | {
      heading: string
      isOpen: boolean
      message: string
      onCloseToast(): void
      type: 'success'
    }
  | {
      heading: string
      isOpen: boolean
      message: string
      onCloseToast(): void
      type: 'error'
    }

let toastRoot: HTMLElement | null = null

// In tests we may not have a document (e.g. tests that don't run in a jsdom environment).
if (typeof document !== 'undefined') {
  toastRoot = document.getElementById('toast-root')
  if (!toastRoot) {
    toastRoot = document.createElement('div')
    toastRoot.setAttribute('id', 'toast-root')
    document.body.appendChild(toastRoot)
  }
}

const Toast = (props: Props) => {
  const { isOpen, onCloseToast } = props

  if (!toastRoot) {
    console.error('Unexpected: No root HTML element for toast portal.')
    return null
  }

  return createPortal(
    <div
      className={clsx(
        'fixed right-16 bottom-16 z-40 flex w-[426px] space-x-4 rounded-xl p-4 transition-all sm:right-1 sm:w-[calc(100%-8px)]',
        {
          'visible translate-x-0 opacity-100': isOpen,
          // The 4rem (and 0.25rem on mobile) here is to account for the "right" positioning of the toast when
          // it's open so there's space between the right edge of the toast and the screen.
          'invisible translate-x-[calc(100%_+_4rem)] opacity-0 sm:translate-x-[calc(100%_+_0.25rem)]':
            !isOpen,
          'bg-red-faded': props.type === 'error',
          'bg-green-faded': props.type === 'success',
        }
      )}
    >
      <ToastContent
        heading={props.heading}
        message={props.message}
        type={props.type}
      />

      <div className="shrink-0">
        <Button
          aria-label="Close"
          buttonStyle="link"
          onClick={() => {
            onCloseToast()
          }}
          size="auto"
        >
          <div className="h-6 w-6">
            <XIcon />
          </div>
        </Button>
      </div>
    </div>,
    toastRoot
  )
}

export default Toast

const ToastContent = ({
  heading,
  message,
  type,
}: {
  heading?: string
  message: ReactNode
  type: Props['type']
}) => {
  let icon: ReactNode | null = null

  if (type === 'success') {
    icon = (
      <div className="h-6 w-6 shrink-0 text-white bg-green rounded-full flex items-center justify-center">
        <div className="w-4 h-4">
          <CheckIcon />
        </div>
      </div>
    )
  }

  return (
    <div className="flex grow items-start space-x-2">
      {icon}
      <div>
        {heading && <h2 className="text-h/14_120 font-semibold">{heading}</h2>}
        <p className="mt-2 text-k/14_120">{message}</p>
      </div>
    </div>
  )
}
