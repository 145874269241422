import axios from 'axios'
import {
  Customer,
  JWTPayload,
  UpdatedAddress,
  UserV1,
} from '@tovala/browser-apis-combinedapi'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import jwtDecode from 'jwt-decode'
import { PayloadAction } from '@reduxjs/toolkit'

import {
  ADDRESS_VALIDATION_SUCCESS,
  GET_COUPON_AMOUNT,
  GET_FULL_CUSTOMER_RECORD,
  GET_PRODUCTS_PURCHASE_INFORMATION,
  GET_PRODUCT_TAX_ESTIMATE,
  GET_USER_INFO_V1,
  REFUND_ORDER_SUCCESS,
  REMOVE_PAYMENT_SOURCE,
  REQUEST_USER_CREATE,
  SAVE_PHONE,
  SAVE_UNVALIDATED_ADDRESS,
  SAVE_VALIDATED_ADDRESS,
  SET_ADDRESS_VALIDATION_MESSAGE,
  SET_PLAN_PREFERENCES,
  SUCCESS_EXCHANGE_OVEN,
  TOGGLE_ADDRESS_VALIDATE_MODAL,
  UPDATE_SHIPPING_ADDRESS,
  VALIDATE_ADDRESS_BUTTON_TEXT,
} from './types'
import { API_URL, API_URL_V1, APP_ID, getHeaders } from './index'
import { AppThunk } from 'store'
import { errorHandler, successHandler } from './notifications'
import { queryClient } from 'utils/api'

export function clearCoupon(): AppThunk<void> {
  return function (dispatch) {
    dispatch({
      type: GET_COUPON_AMOUNT,
      payload: '',
    })
  }
}

export function getCouponAmount(payload: {
  couponCode: string
  productid: string
  userid: number
}): AppThunk<
  Promise<PayloadAction<
    {
      couponCode: {
        code: string
        creditAmountCents: number
        description: string
        dollarAmount: string
        dollarPercent: string
        expiration: null
        mealsAmount: number
        promoInfo: null
        promoType: string
      }
      reason: string
      type: string
      valid: boolean
    },
    typeof GET_COUPON_AMOUNT
  > | void>
> {
  const { couponCode, productid, userid } = payload

  return function (dispatch) {
    dispatch(showLoading())

    return axios
      .post(
        `${API_URL_V1}/coupons/${couponCode}/validate`,
        { productid, userid },
        getHeaders()
      )
      .then((response) => {
        dispatch(hideLoading())
        return dispatch({
          type: GET_COUPON_AMOUNT,
          payload: response.data,
        })
      })
      .catch((error) => {
        dispatch(hideLoading())
        console.log(error)
        return errorHandler(
          dispatch,
          error,
          'The coupon code you entered is not valid.'
        )
      })
  }
}

export function getTaxByAddress(
  payload: unknown
): AppThunk<
  Promise<PayloadAction<
    { priceWithDiscount: number; skuPrice: number; taxAmount: number },
    typeof GET_PRODUCT_TAX_ESTIMATE
  > | void>
> {
  return function (dispatch) {
    return axios
      .post(`${API_URL_V1}/taxByAddress`, payload, getHeaders())
      .then((response) => {
        return dispatch({
          type: GET_PRODUCT_TAX_ESTIMATE,
          payload: response.data,
        })
      })

      .catch((error) => {
        console.log(error)
        dispatch(hideLoading())
      })
  }
}

export function createNewUser(email: string): AppThunk<Promise<number | void>> {
  return function (dispatch) {
    const type = 'user'
    dispatch(showLoading())
    dispatch({ type: REQUEST_USER_CREATE })

    return axios
      .post(
        `${API_URL}/getTokenUpdated`,
        { email, type },
        { headers: { 'X-Tovala-AppID': APP_ID } }
      )
      .then((response) => {
        if (response.status === 200) {
          const userToken = response.data.token
          const decodedUserToken = jwtDecode<JWTPayload>(userToken)
          const userId = decodedUserToken.userId

          successHandler(dispatch, 'Success! User created.')

          dispatch(hideLoading())

          return userId
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch(hideLoading())
        const message =
          'There was a problem creating this user account. This email address may already be in use.'
        errorHandler(dispatch, null, message)
      })
  }
}

export function setGoodCustomer(
  userid: string,
  good_customer: boolean,
  notes: string
): AppThunk<void> {
  return function (dispatch) {
    axios
      .put(
        `${API_URL}/users/${userid}/goodCustomer`,
        { good_customer, notes },
        getHeaders()
      )
      .then((response) => {
        if (response.status === 200) {
          successHandler(dispatch, 'Good_customer flag updated')

          dispatch(getUserInfo(userid))
        }
      })
      .catch((error) => {
        errorHandler(dispatch, error)
        alert('There was an Error. ' + error)
      })
  }
}

// POST https://api.tovala.com/v0/users/:userID/userTermOrders/:userTermOrderId/refund/:orderStatus
export function refundOrder(
  userid: number | string,
  orderid: string,
  orderStatus: string,
  data: unknown
): AppThunk<
  Promise<PayloadAction<boolean, typeof REFUND_ORDER_SUCCESS> | void>
> {
  return function (dispatch) {
    return axios
      .post(
        `${API_URL}/users/${userid}/userTermOrders/${orderid}/refund/${orderStatus}`,
        data,
        getHeaders()
      )
      .then((response) => {
        let message = 'Order refunded successfully'

        if (orderStatus === 'canceled') {
          message = 'Order canceled successfully'
        }

        // Return success message
        if (response.status === 200) {
          successHandler(dispatch, message)

          return dispatch({
            type: REFUND_ORDER_SUCCESS,
            payload: true,
          })
        }
      })
      .catch((error) => {
        errorHandler(dispatch, error)
        alert('There was an Error. Refund not Issued! ' + error)
      })
  }
}

// POST https://api.tovala.com/v0/users/:userID/userTermOrders/:userTermOrderID/partialRefund
export function partialRefundOrder(
  userid: number | string,
  orderid: string,
  data: unknown
): AppThunk<
  Promise<PayloadAction<boolean, typeof REFUND_ORDER_SUCCESS> | void>
> {
  return function (dispatch) {
    return axios
      .post(
        `${API_URL}/users/${userid}/userTermOrders/${orderid}/partialRefund`,
        data,
        getHeaders()
      )
      .then((response) => {
        if (response.status === 200) {
          successHandler(dispatch, 'Order partially refunded successfully.')
          return dispatch({
            type: REFUND_ORDER_SUCCESS,
            payload: true,
          })
        }
      })
      .catch((error) => {
        errorHandler(dispatch, error)
        alert('There was an Error. Refund not Issued! ' + error)
      })
  }
}

// PUT
// https://api.tovala.com/v0/users/:userID/customers/:customerID/defaultPaymentSource
export function setDefaultPaymentSource(
  userid: string,
  customerId: string,
  sourceid: string
): AppThunk<void> {
  return function (dispatch) {
    axios
      .put(
        `${API_URL}/users/${userid}/customers/${customerId}/defaultPaymentSource`,
        { sourceid },
        getHeaders()
      )
      .then((response) => {
        // Return success message
        if (response.status === 200) {
          successHandler(dispatch, 'Default payment source saved!')
        }

        // GET // https://api.tovala.com/v0/users/:userID/customers/:customerID
        return axios.get(
          `${API_URL}/users/${userid}/customers/${customerId}`,
          getHeaders()
        )
      })

      .then((response) => {
        dispatch({
          type: GET_FULL_CUSTOMER_RECORD,
          payload: response.data,
        })
      })

      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// DELETE
// https://api.tovala.com/v0/users/:userID/customers/:customerID/sources/:sourceID
export function removePaymentSource(
  userid: string,
  customerid: string,
  sourceid: string
): AppThunk<void> {
  return function (dispatch) {
    axios
      .delete(
        `${API_URL}/users/${userid}/customers/${customerid}/sources/${sourceid}`,
        getHeaders()
      )
      .then((response) => {
        dispatch({
          type: REMOVE_PAYMENT_SOURCE,
        })

        // Return success message
        if (response.status === 200) {
          successHandler(dispatch, 'Payment source removed.')
        }

        // GET // https://api.tovala.com/v0/users/:userID/customers/:customerID
        return axios.get(
          `${API_URL}/users/${userid}/customers/${customerid}`,
          getHeaders()
        )
      })

      .then((response) => {
        dispatch({
          type: GET_FULL_CUSTOMER_RECORD,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// GET // https://api.tovala.com/v0/users/:userID/customers/:customerID
export function getFullCustomerRecord(
  userID: number | string,
  customerID: string
): AppThunk<
  Promise<PayloadAction<Customer, typeof GET_FULL_CUSTOMER_RECORD> | void>
> {
  return function (dispatch) {
    return axios
      .get(`${API_URL}/users/${userID}/customers/${customerID}`, getHeaders())
      .then((response) => {
        return dispatch({
          type: GET_FULL_CUSTOMER_RECORD,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// Get specfic user (USER)
export function getUserInfo(
  userID: number | string
): AppThunk<Promise<PayloadAction<UserV1, typeof GET_USER_INFO_V1> | void>> {
  // This is not ideal, but until we're off of Redux, this is the best way to ensure
  // we're always refreshing all user data at the same time. This relies on knowing
  // the query key for v0 users, which is an abstraction leak.
  queryClient.invalidateQueries(['users-v0', Number(userID)])

  return function (dispatch) {
    return axios
      .get<UserV1>(`${API_URL_V1}/users/${userID}`, getHeaders())
      .then((response) => {
        const user = response.data

        if (user.subscription) {
          const planPreferences = {
            defaultShipPeriod: user.subscription.defaultShipPeriod,
            doNotReplace: user.subscription.isDoNotReplace,
            premiumMealsOk: user.subscription.isPremiumMealsOk,
            hasBlackSheetTray: user.subscription.hasBlackSheetTray,
            autofillBreakfastOK: user.subscription.autofillBreakfastOK,
            autofillSurchargeOK: user.subscription.autofillSurchargeOK,
            single: !user.subscription.isDouble,
          }

          dispatch({
            type: SET_PLAN_PREFERENCES,
            payload: planPreferences,
          })
        }

        dispatch(getFullCustomerRecord(userID, user.subscription.customerID))

        return dispatch({
          type: GET_USER_INFO_V1,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// PUT
// https://api.tovala.com/v0/users/:userID/customers/:customerID/shipping
export function updateShippingAddress(
  userId: number | string,
  customerId: string,
  formProps: {
    city: string
    line1: string
    line2: string
    name: string
    phone: string
    postal_code: string
    state: string
  }
): AppThunk<
  Promise<PayloadAction<UpdatedAddress, typeof UPDATE_SHIPPING_ADDRESS> | void>
> {
  return function (dispatch) {
    dispatch(showLoading())
    // Construct payload
    const name = formProps.name
    let phone = ''
    if (formProps && formProps.phone) {
      phone = formProps.phone.replace(/-/g, '')
    }
    const address = {
      line1: formProps.line1,
      line2: formProps.line2,
      city: formProps.city,
      state: formProps.state,
      postal_code: formProps.postal_code,
    }

    return axios
      .put<{
        address: UpdatedAddress
        error: boolean
        message: 'AddressUpdated'
      }>(
        `${API_URL}/users/${userId}/customers/${customerId}/shipping?ignoreValidation=true`,
        { phone, name, address },
        getHeaders()
      )

      .then((response) => {
        if (response.status === 200) {
          dispatch(hideLoading())
          successHandler(dispatch, 'Boom! Shipping information has been saved.')

          // Hide validate modal
          dispatch({
            type: TOGGLE_ADDRESS_VALIDATE_MODAL,
            payload: false,
          })

          return dispatch({
            type: UPDATE_SHIPPING_ADDRESS,
            payload: response.data.address,
          })
        }
      })
      .catch((error) => {
        dispatch(hideLoading())
        errorHandler(dispatch, error)
      })
  }
}

// PUT
// https://api.tovala.com/v0/users/:userID/customers/:customerID/shipping
export function validateShippingAddress(
  userId: number | string,
  customerId: string,
  addressData: {
    city: string
    line1: string
    line2: string
    name: string
    phone: string
    postal_code: string
    state: string
  }
): AppThunk<
  Promise<PayloadAction<
    {
      name: string
      phone: string
      line1: string
      line2: string
      city: string
      state: string
      postal_code: string
    },
    typeof ADDRESS_VALIDATION_SUCCESS
  > | void>
> {
  return function (dispatch) {
    const name = addressData.name
    const phone = addressData.phone.replace(/-/g, '')
    const address = {
      line1: addressData.line1,
      line2: addressData.line2,
      city: addressData.city,
      state: addressData.state,
      postal_code: addressData.postal_code,
    }

    // Save address locally, use later if validation is ignored.
    dispatch({
      type: SAVE_UNVALIDATED_ADDRESS,
      payload: addressData,
    })

    // Save phone locally, use later if validation is ignored.
    dispatch({
      type: SAVE_PHONE,
      payload: phone,
    })

    dispatch(showLoading())

    // Call validate endpoint
    return axios
      .post<{
        address: {
          address_type: string
          city: string
          country: string
          estimated_delivery_days: number
          line1: string
          line2: string
          name: string
          phone: string
          postal_code: string
          ship_day_of_the_week: string
          shipping_company: string
          shipping_origin: string
          shipping_service: string
          state: string
          updated: string
          userid: number
        }
        dpv_match_code: string
        valid: boolean
      }>(
        `${API_URL}/users/${userId}/customers/${customerId}/shipping/validate`,
        { phone, name, address },
        getHeaders()
      )
      .then((response) => {
        dispatch(hideLoading())

        if (response.status === 200) {
          // Y — Confirmed; entire address was DPV confirmed deliverable.
          if (response.data.dpv_match_code === 'Y') {
            const validatedData = {
              name: addressData.name,
              phone: addressData.phone,
              line1: response.data.address.line1,
              line2: response.data.address.line2,
              city: response.data.address.city,
              state: response.data.address.state,
              postal_code: response.data.address.postal_code,
            }

            return dispatch({
              type: ADDRESS_VALIDATION_SUCCESS,
              payload: validatedData,
            })
          }

          // Show validate modal
          dispatch({
            type: TOGGLE_ADDRESS_VALIDATE_MODAL,
            payload: true,
          })

          if (response.data.address) {
            dispatch({
              type: SAVE_VALIDATED_ADDRESS,
              payload: response.data.address,
            })
          }

          // Smarty Streets docs: https://smartystreets.com/docs/cloud/us-street-api
          if (response.data.dpv_match_code === 'D') {
            dispatch({
              type: SET_ADDRESS_VALIDATION_MESSAGE,
              payload:
                'Looks like you did not provide an apartment/suite number. Please update the address and try again.',
            })

            dispatch({
              type: VALIDATE_ADDRESS_BUTTON_TEXT,
              payload: 'Edit Address',
            })
          }

          if (response.data.dpv_match_code === 'N') {
            dispatch({
              type: SET_ADDRESS_VALIDATION_MESSAGE,
              payload: 'Looks like the address could not be confirmed.',
            })

            dispatch({
              type: VALIDATE_ADDRESS_BUTTON_TEXT,
              payload: 'Use Suggested Address',
            })
          }

          if (response.data.dpv_match_code === 'S') {
            dispatch({
              type: SET_ADDRESS_VALIDATION_MESSAGE,
              payload:
                'We could not find an exact match for this shipping address. Please verify the address with the customer before saving the address as entered.',
            })

            dispatch({
              type: VALIDATE_ADDRESS_BUTTON_TEXT,
              payload: 'Edit Address',
            })
          }

          // If DPV_MATCH_CODE is empty, have user re-enter code
          if (response.data.dpv_match_code === '') {
            dispatch({
              type: SET_ADDRESS_VALIDATION_MESSAGE,
              payload:
                'We could not find an exact match for this shipping address. Please verify the address with the customer before saving the address as entered.',
            })

            dispatch({
              type: VALIDATE_ADDRESS_BUTTON_TEXT,
              payload: 'Edit Address',
            })
          }

          // end else
        }
      })

      .catch((error) => {
        console.log('error is' + error)
        errorHandler(dispatch, error)
      })
  }
}

export function setAddressValidateModalVisiblity(
  value: boolean
): PayloadAction<boolean, typeof TOGGLE_ADDRESS_VALIDATE_MODAL> {
  return {
    type: TOGGLE_ADDRESS_VALIDATE_MODAL,
    payload: value,
  }
}

// GET
// https://api.tovala.com/v0/users/:userID/productsPurchaseInformation
export function getProductsPurchaseInformation(
  userId: number | string
): AppThunk<void> {
  return function (dispatch) {
    axios
      .get(
        `${API_URL}/users/${userId}/productsPurchaseInformation`,
        getHeaders()
      )
      .then((response) => {
        dispatch({
          type: GET_PRODUCTS_PURCHASE_INFORMATION,
          payload: response.data,
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

export function applyGiftCard(
  userId: string,
  cardID: string,
  email: string
): AppThunk<void> {
  return function (dispatch) {
    dispatch(showLoading())
    axios
      .put(
        `${API_URL}/users/${userId}/giftCard/${cardID}`,
        { cardID },
        getHeaders()
      )
      .then((response) => {
        dispatch(hideLoading())

        if (response.status === 200) {
          successHandler(
            dispatch,
            `Hooray! The gift card balance has been applied to ${email}.`
          )

          return dispatch(getUserInfo(userId))
        }
      })
      .catch((error) => {
        dispatch(hideLoading())
        let errorMessage = error.response.data.message

        if (errorMessage === 'GiftCardNotFound') {
          errorMessage = 'That gift code does not exist.'
        }

        if (errorMessage === 'GiftCardRedeemedByOtherUser') {
          errorMessage =
            "That gift code has already been applied to another user's account."
        }

        if (errorMessage === 'GiftCardAlreadyRedeemed') {
          errorMessage = 'That gift code has already been applied.'
        }

        errorHandler(dispatch, error, errorMessage)
      })
  }
}

// POST https://api.tovala.com/v0/users/:userID/productsPurchase/:purchaseID/applyDiscount
export function applyDiscountPostPurchase(
  userid: number,
  purchaseid: string,
  coupon_code: string,
  notes: string,
  amount_cents: number
): AppThunk<void> {
  return function (dispatch) {
    dispatch(showLoading())

    axios
      .post(
        `${API_URL}/users/${userid}/productsPurchase/${purchaseid}/applyDiscount`,
        { coupon_code, notes, amount_cents },
        getHeaders()
      )
      .then((response) => {
        dispatch(hideLoading())

        if (response.status === 200) {
          successHandler(
            dispatch,
            `Success! $${amount_cents / 100} discount applied to order.`
          )

          dispatch(clearCoupon())

          return dispatch(getProductsPurchaseInformation(userid))
        }
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// POST https://api.tovala.com/v0/users/:userID/productsPurchase/:purchaseID/refund/:refundedStatus
export function refundOvenPurchase(
  userid: number,
  purchaseid: string,
  refundedStatus: string,
  fulfillmentCanceled: boolean,
  notes: string
): AppThunk<void> {
  return function (dispatch) {
    dispatch(showLoading())

    axios
      .post(
        `${API_URL}/users/${userid}/productsPurchase/${purchaseid}/refund/${refundedStatus}?fulfillmentCancelled=${fulfillmentCanceled}`,
        { notes },
        getHeaders()
      )
      .then((response) => {
        dispatch(hideLoading())

        if (response.status === 200) {
          successHandler(dispatch, `Success! Order ${refundedStatus}.`)

          return dispatch(getProductsPurchaseInformation(userid))
        }
      })
      .catch((error) => {
        dispatch(hideLoading())

        errorHandler(dispatch, error)
      })
  }
}

// POST https://api.tovala.com/v1/users/:userID/refundPurchase
// Currently only used for canceling back ordered ovens prior to fulfillment
export function refundPurchase(
  userid: number,
  data: { status: string }
): AppThunk<void> {
  return function (dispatch) {
    dispatch(showLoading())

    axios
      .post(`${API_URL_V1}/users/${userid}/refundPurchase`, data, getHeaders())
      .then((response) => {
        dispatch(hideLoading())

        if (response.status === 200) {
          successHandler(dispatch, `Success! Order ${data.status}.`)

          return dispatch(getProductsPurchaseInformation(userid))
        }
      })
      .catch((error) => {
        dispatch(hideLoading())

        errorHandler(dispatch, error)
      })
  }
}

export function validateCoupon(
  productid: string,
  couponCode: string,
  userid = 0
): AppThunk<void> {
  return function (dispatch) {
    dispatch(showLoading())
    axios
      .post(
        `${API_URL_V1}/coupons/${couponCode}/validate`,
        { productid, userid },
        getHeaders()
      )
      .then((response) => {
        dispatch(hideLoading())
        if (response.status === 200) {
          const coupon = response.data

          if (coupon.valid) {
            successHandler(dispatch, 'This coupon is valid!')
          } else {
            let message = 'This coupon is not valid for this product.'
            if (coupon.reason === 'InvalidCoupon-AlreadyRedeemedByUser') {
              message = 'This coupon has already been redeemed by this user.'
            }
            errorHandler(dispatch, null, message)
          }
        }
      })
      .catch((error) => {
        errorHandler(dispatch, error)
      })
  }
}

// POST  v1/tools/users/:userID/exchangeOven/:purchaseID
export function sendExchangeOven(
  userid: number,
  purchaseid: string,
  payload: unknown
): AppThunk<
  Promise<PayloadAction<boolean, typeof SUCCESS_EXCHANGE_OVEN> | void>
> {
  return function (dispatch) {
    dispatch(showLoading())
    return axios
      .post(
        `${API_URL_V1}/tools/users/${userid}/exchangeOven${
          purchaseid && `/${purchaseid}`
        }`,
        payload,
        getHeaders()
      )
      .then(() => {
        dispatch(hideLoading())
        successHandler(
          dispatch,
          `Success! Exchange oven fulfillment created for User #${userid}`
        )

        return dispatch({
          type: SUCCESS_EXCHANGE_OVEN,
          payload: true,
        })
      })
      .catch((error) => {
        dispatch(hideLoading())
        errorHandler(dispatch, error)
      })
  }
}
