import {
  ErrorCodeMessageMapCombinedAPI,
  ListingsReceipt,
  OrderHistoryReceipt,
  UserV1,
  useInvalidateReceipts,
  useUpdateOrderListingSelections,
} from '@tovala/browser-apis-combinedapi'
import {
  APIErrorDisplay,
  ButtonLoading,
  Checkbox,
  Textarea,
} from '@tovala/component-library'
import { getUserInfo } from 'actions/auth'
import { successHandler } from 'actions/notifications'
import Modal, { ModalBody, ModalHeader } from 'components/modals/Modal'
import { useAppDispatch } from 'hooks'
import { difference } from 'lodash-es'
import { useState } from 'react'
import { formatCentsToDollars } from 'utils/currency'

const UPDATE_ORDER_LISTING_SELECTIONS_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't remove these extras due to a technical issue on our end.",
  },
  'OrderUpdateNotAllowed-OutsideTheAllowedWindow': {
    why: 'Extras cannot be removed after 12PM CT.',
  },
}

export interface Props {
  onCloseModal(): void
  listingsReceipt: ListingsReceipt
  receipt: OrderHistoryReceipt
  user: UserV1
}

const RemoveExtrasModal = ({
  listingsReceipt,
  onCloseModal,
  receipt,
  user,
}: Props) => {
  const dispatch = useAppDispatch()
  const {
    mutate: updateOrderListingSelections,
    error: updateOrderListingSelectionsError,
    isError: hasUpdateOrderListingSelectionsError,
    isLoading: isUpdatingOrderListingSelections,
  } = useUpdateOrderListingSelections()

  const { invalidateListingsReceipt, invalidateOrderHistoryReceipts } =
    useInvalidateReceipts()

  const [listingsToRemove, setListingsToRemove] = useState(new Set<string>())
  const [notes, setNotes] = useState<string>('')

  const listingsWithIdentifier = listingsReceipt.listings.map(
    (listing, index) => {
      const identifier = `${listing.id}|${index}`
      return {
        ...listing,
        identifier,
      }
    }
  )

  const removedSelections = listingsWithIdentifier.filter((listing) => {
    return listingsToRemove.has(listing.identifier)
  })

  const isNoChargeOrder = receipt.paymentStatus === ''
  const estimatedRefundTotalCents =
    !isNoChargeOrder && removedSelections.length > 0
      ? removedSelections.reduce((total, listing) => {
          const price = listing?.priceCents ?? 0
          return total + price
        }, 0)
      : 0

  const handleUpdateOrder = () => {
    const updatedListings = difference(
      listingsWithIdentifier.map((listing) => listing.identifier),
      Array.from(listingsToRemove)
    ).map((listingSelection) => {
      // Remove identifier
      const listingID = listingSelection.split('|')[0]
      return listingID
    })

    updateOrderListingSelections(
      {
        data: {
          ids: updatedListings,
          notes,
        },
        orderID: receipt.userTermOrderID,
        userID: user.id,
      },
      {
        onSuccess: () => {
          successHandler(dispatch, 'Success! Extras removed from order.')

          invalidateListingsReceipt(user.id, receipt.userTermOrderID)
          invalidateOrderHistoryReceipts(user.id)
          dispatch(getUserInfo(user.id))

          onCloseModal()
        },
      }
    )
  }

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <ModalHeader onClickClose={onCloseModal}>
          Remove Extras - Term #{receipt.termID}
        </ModalHeader>

        <div className="w-[800px]">
          <div className="space-y-6">
            <p>
              Select Extras below to remove them from the user's order before it
              ships. Extras cannot be re-added once removed at this time.
            </p>

            {listingsReceipt && listingsReceipt.listings.length > 0 && (
              <div className="space-y-4">
                <p className="font-bold">Extras to remove</p>
                {listingsWithIdentifier.map((listing) => {
                  return (
                    <Checkbox
                      key={listing.identifier}
                      checked={listingsToRemove.has(listing.identifier)}
                      label={
                        <p>
                          {listing.title} | $
                          {(listing.priceCents / 100).toFixed(2)}
                        </p>
                      }
                      name={listing.identifier}
                      onChange={() => {
                        setListingsToRemove((listings) => {
                          const updatedListings = new Set(listings)

                          if (updatedListings.has(listing.identifier)) {
                            updatedListings.delete(listing.identifier)
                          } else {
                            updatedListings.add(listing.identifier)
                          }

                          return updatedListings
                        })
                      }}
                    />
                  )
                })}

                {!isNoChargeOrder ? (
                  <p className="font-bold">
                    Estimated Refund:{' '}
                    {formatCentsToDollars(estimatedRefundTotalCents)} + tax
                  </p>
                ) : (
                  <p className="font-bold">
                    This order was provided to the user free of charge. No
                    refund will be issued.
                  </p>
                )}
              </div>
            )}

            <div>
              <label className="font-bold" htmlFor="notes">
                Notes*
              </label>
              <Textarea
                id="notes"
                name="notes"
                onChange={(event) => {
                  setNotes(event.target.value)
                }}
                required
                rows={5}
                value={notes}
              />
            </div>

            {hasUpdateOrderListingSelectionsError && (
              <APIErrorDisplay
                error={updateOrderListingSelectionsError}
                errorCodeMessageMap={UPDATE_ORDER_LISTING_SELECTIONS_ERRORS}
              />
            )}
            <div>
              <ButtonLoading
                disabled={listingsToRemove.size === 0 || !notes}
                isLoading={isUpdatingOrderListingSelections}
                onClick={() => {
                  handleUpdateOrder()
                }}
                size="large"
              >
                Update Order
              </ButtonLoading>
            </div>
            <p>
              Note: After updating the order, the order's surcharge and total
              amounts and "Extras Ordered" will be updated to reflect the
              changes in Extras rather than being in a refunded state.
            </p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default RemoveExtrasModal
