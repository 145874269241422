import { TextImageStack as TextImageStackComponent } from '@tovala/component-library'
import MenuGridLayout from '../MenuGridLayout'
import TwoMealPicker from './TwoMealPicker'
import MealWithExtra from './MealWithExtra'
import MealCarousel from './MealCarousel'
import {
  MenuComponentStandardized,
  MenuComponentStandardizedTextImageStack,
} from '@tovala/browser-apis-menu-components'
import Meal from './Meal'
import { ViewType } from '../utils'
import ComponentToolbar from '../ComponentToolbar'
import { DraggableNewComponentProps } from '../DraggableNewComponent'

const TextImageStack = ({
  onClickEdit,
  properties,
  viewType,
}: {
  onClickEdit?(opts: {
    componentType: DraggableNewComponentProps['componentType']
    data: { component: MenuComponentStandardized; index?: number }
  }): void
  properties: MenuComponentStandardizedTextImageStack['properties']
  viewType: ViewType
}) => {
  const { children, image, subtitle, title } = properties
  return (
    <TextImageStackComponent image={image} subtitle={subtitle} title={title}>
      <MenuGridLayout viewType={viewType}>
        {children?.map((child) => {
          if (child.type === 'meal') {
            return (
              <div key={`meal-${child.properties.id}`} className="md:px-4">
                <Meal properties={child.properties} />
              </div>
            )
          } else if (child.type === 'mealWithExtra') {
            return (
              <div
                key={`mealWithExtra-${child.properties.meal.id}`}
                className="group/meal relative"
              >
                <MealWithExtra properties={child.properties} />

                {onClickEdit && (
                  <div className="absolute left-0 right-0 bottom-0 z-10 hidden justify-center space-x-4 rounded-b-2xl bg-grey-3/60 py-4 group-hover/meal:flex">
                    <ComponentToolbar
                      onClickEdit={() => {
                        onClickEdit({
                          componentType: 'mealWithExtra',
                          data: { component: child },
                        })
                      }}
                    />
                  </div>
                )}
              </div>
            )
          } else if (child.type === 'twoMealPicker') {
            return (
              <div
                key={`twoMealPicker-${child.properties.meals[0].id}`}
                className="md:px-4"
              >
                <TwoMealPicker properties={child.properties} />
              </div>
            )
          } else if (child.type === 'animatedMealCarousel') {
            return (
              <div key={`mealCarousel-${child.properties.mealOptions[0].id}`}>
                <MealCarousel properties={child.properties} />
              </div>
            )
          }
        })}
      </MenuGridLayout>
    </TextImageStackComponent>
  )
}

export default TextImageStack
