import {
  DndContext,
  MouseSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { MenuComponentStandardized } from '@tovala/browser-apis-menu-components'
import {
  Button,
  ButtonLoading,
  ButtonRound,
  Checkbox,
  FormFieldError,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  Textarea,
  TrashIcon,
  XIcon,
} from '@tovala/component-library'
import { compact, findIndex, flatMap } from 'lodash-es'
import { useState } from 'react'
import { Controller, FieldErrors, useForm } from 'react-hook-form'
import ImageUpload, { ImageFormData } from '../ImageUpload'
import SortableComponent from '../SortableComponent'
import { ComponentDropPlaceholder } from '../utils'

interface TextImageStackFormData {
  associatedComponentIDs: Set<string>
  image?: ImageFormData
  subtitle?: string
  title: string
}

const TextImageStackDialog = ({
  componentID,
  components,
  initialValues,
  isUploadingImage,
  onClose,
  onSave,
}: {
  componentID: string | undefined
  components: (ComponentDropPlaceholder | MenuComponentStandardized)[]
  initialValues: TextImageStackFormData | undefined
  isUploadingImage: boolean
  onClose(): void
  onSave(data: TextImageStackFormData): void
}) => {
  const [isAddingMeals, setIsAddingMeals] = useState(false)
  const { control, formState, handleSubmit, register, setValue, watch } =
    useForm<TextImageStackFormData>({
      defaultValues: initialValues,
      resolver: customResolver,
    })

  const associatedComponentIDs = watch('associatedComponentIDs')
  const image = watch('image')

  const associatedMealsOptions = compact(
    flatMap(components, (component) => {
      if (component.type === 'meal') {
        return {
          label: component.properties.title,
          value: component.id,
        }
      } else if (component.type === 'mealWithExtra') {
        return {
          label: component.properties.meal.title,
          value: component.id,
        }
      } else if (component.type === 'twoMealPicker') {
        return {
          label: component.properties.meals[0].title,
          value: component.id,
        }
      } else if (component.type === 'animatedMealCarousel') {
        return {
          label: component.properties.mealOptions[0].title,
          value: component.id,
        }
      } else if (
        component.type === 'textImageStack' &&
        component.id === componentID
      ) {
        return component.properties.children.map((child) => {
          if (child.type === 'meal') {
            return {
              label: child.properties.title,
              value: child.id,
            }
          } else if (child.type === 'mealWithExtra') {
            return {
              label: child.properties.meal.title,
              value: child.id,
            }
          } else if (child.type === 'twoMealPicker') {
            return {
              label: child.properties.meals[0].title,
              value: child.id,
            }
          } else if (child.type === 'animatedMealCarousel') {
            return {
              label: child.properties.mealOptions[0].title,
              value: child.id,
            }
          }
        })
      }
    })
  )

  // Some components have children that could be meal components we want to
  // search through to display a preview. This will happen with an existing
  // textImageStack - we want to show that the children meals have been chosen.
  const flatComponents = components.flatMap((component) => {
    if (component.type === 'textImageStack') {
      return component.properties.children
    }

    return component
  })

  const associatedMealPreviews = compact(
    Array.from(associatedComponentIDs).map((componentID) => {
      const component = flatComponents.find(
        (component) => component.id === componentID
      )
      if (!component) {
        return
      }

      if (component.type === 'meal') {
        return {
          id: component.id,
          image: component.properties.image,
          subtitle: component.properties.subtitle,
          title: component.properties.title,
        }
      } else if (component.type === 'mealWithExtra') {
        return {
          id: component.id,
          image: component.properties.meal.image,
          subtitle: component.properties.meal.subtitle,
          title: component.properties.meal.title,
        }
      } else if (component.type === 'twoMealPicker') {
        return {
          id: component.id,
          image: component.properties.meals[0].image,
          subtitle: component.properties.meals[0].subtitle,
          title: component.properties.meals[0].title,
        }
      } else if (component.type === 'animatedMealCarousel') {
        return {
          id: component.id,
          image: component.properties.mealOptions[0].image,
          subtitle: component.properties.mealOptions[0].subtitle,
          title: component.properties.mealOptions[0].title,
        }
      }
    })
  )

  const [activeID, setActiveID] = useState<UniqueIdentifier | null>(null)
  const getIndex = (id: UniqueIdentifier) =>
    findIndex(associatedMealPreviews, (component) => component.id === id)
  const activeIndex = activeID ? getIndex(activeID) : -1

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating.
      // Slight distance prevents sortable logic messing with
      // interactive elements in the component.
      activationConstraint: {
        distance: 10,
      },
    })
  )

  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader
        onClickClose={() => {
          if (isAddingMeals) {
            setIsAddingMeals(false)
          } else {
            onClose()
          }
        }}
      >
        Text Image Stack
      </ModalHeader>
      <form onSubmit={handleSubmit(onSave)}>
        <div className="p-6 pb-10 font-sans-new">
          <div className="w-[500px] space-y-4">
            {isAddingMeals ? (
              <FormGroup label="Associated Meals">
                <div className="w-min mb-4">
                  {associatedComponentIDs.size ===
                  associatedMealsOptions.length ? (
                    <Button
                      buttonStyle="link"
                      onClick={() => {
                        setValue('associatedComponentIDs', new Set())
                      }}
                      size="fluid"
                    >
                      Deselect All
                    </Button>
                  ) : (
                    <Button
                      buttonStyle="link"
                      onClick={() => {
                        const newAssociatedComponentIDs = new Set(
                          associatedMealsOptions.map((option) => option.value)
                        )

                        setValue(
                          'associatedComponentIDs',
                          newAssociatedComponentIDs
                        )
                      }}
                      size="fluid"
                    >
                      Select All
                    </Button>
                  )}
                </div>

                <Controller
                  control={control}
                  name="associatedComponentIDs"
                  render={() => (
                    <div>
                      <div className="space-y-2">
                        {associatedMealsOptions.map((option) => {
                          return (
                            <Checkbox
                              key={option.value}
                              checked={associatedComponentIDs.has(option.value)}
                              label={<p>{option.label}</p>}
                              name={option.value}
                              onChange={(event) => {
                                const newAssociatedComponentIDs = new Set(
                                  associatedComponentIDs
                                )
                                if (event.target.checked) {
                                  newAssociatedComponentIDs.add(option.value)
                                } else {
                                  newAssociatedComponentIDs.delete(option.value)
                                }
                                setValue(
                                  'associatedComponentIDs',
                                  newAssociatedComponentIDs
                                )
                              }}
                            />
                          )
                        })}
                      </div>
                    </div>
                  )}
                />
              </FormGroup>
            ) : (
              <div className="grid grid-cols-[1fr_120px] gap-4">
                <FormGroup label="Image">
                  <Controller
                    control={control}
                    name="image"
                    render={({ field }) => (
                      <ImageUpload
                        error={formState.errors.image?.message}
                        hasError={!!formState.errors.image}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        onImageAdded={(data) => {
                          setValue('image', data, { shouldValidate: true })
                        }}
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup label="Preview">
                  <div className="group relative">
                    <img src={image?.src} />

                    <div className="absolute inset-0 hidden items-center justify-center bg-grey-3/60 py-4 group-hover:flex">
                      <ButtonRound
                        buttonSize="small"
                        buttonStyle="dark"
                        icon={<TrashIcon />}
                        label="Delete"
                        onClick={() => {
                          setValue('image', undefined)
                        }}
                      />
                    </div>
                  </div>
                </FormGroup>

                <div className="col-span-2">
                  <FormGroup
                    error={formState.errors.title?.message}
                    label="Title"
                  >
                    <Input
                      hasError={!!formState.errors.title}
                      type="text"
                      {...register('title')}
                    />
                  </FormGroup>
                </div>
                <div className="col-span-2">
                  <FormGroup label="Subtitle">
                    <Textarea rows={4} {...register('subtitle')} />
                  </FormGroup>
                </div>
                <div className="flex justify-between items-center col-span-2 border-t border-gray pt-4">
                  <h3 className="text-h/14_120 font-semibold ">
                    Associated Meals
                  </h3>
                  <Button
                    buttonStyle="gray"
                    onClick={() => {
                      setIsAddingMeals(true)
                    }}
                    size="medium"
                  >
                    Edit
                  </Button>
                </div>

                <div className="space-y-1">
                  {formState.errors.associatedComponentIDs?.message && (
                    <FormFieldError>
                      {formState.errors.associatedComponentIDs.message}
                    </FormFieldError>
                  )}
                </div>

                <div className="col-span-2 space-y-4">
                  <DndContext
                    onDragEnd={() => {
                      setActiveID(null)
                    }}
                    onDragOver={({ over }) => {
                      if (!over) {
                        return
                      }

                      const overIndex = getIndex(over.id)

                      if (activeIndex !== overIndex) {
                        const updatedOrder = arrayMove(
                          Array.from(associatedComponentIDs),
                          activeIndex,
                          overIndex
                        )
                        setValue(
                          'associatedComponentIDs',
                          new Set(updatedOrder)
                        )
                      }
                    }}
                    onDragStart={({ active }) => {
                      if (!active) {
                        return
                      }

                      setActiveID(active.id)
                    }}
                    sensors={sensors}
                  >
                    <SortableContext
                      items={associatedMealPreviews}
                      strategy={verticalListSortingStrategy}
                    >
                      <div className="grid grid-cols-1 gap-y-4">
                        {associatedMealPreviews.map(
                          ({ id, image, subtitle, title }) => {
                            return (
                              <SortableComponent
                                key={id}
                                componentID={id}
                                componentType={null}
                              >
                                <div
                                  key={id}
                                  className="flex items-center justify-between space-x-4"
                                >
                                  <div className="flex items-center space-x-4">
                                    <img
                                      className="h-16 rounded-md"
                                      src={image.url}
                                    />
                                    <div>
                                      <div>{title}</div>
                                      <div className="text-sm">{subtitle}</div>
                                    </div>
                                  </div>

                                  <button
                                    className="h-8 w-8 shrink-0 flex items-center justify-center"
                                    onClick={() => {
                                      const newAssociatedComponentIDs = new Set(
                                        associatedComponentIDs
                                      )
                                      newAssociatedComponentIDs.delete(id)

                                      setValue(
                                        'associatedComponentIDs',
                                        newAssociatedComponentIDs
                                      )
                                    }}
                                    type="button"
                                  >
                                    <div className="w-5 h-5">
                                      <XIcon />
                                    </div>
                                  </button>
                                </div>
                              </SortableComponent>
                            )
                          }
                        )}
                      </div>
                    </SortableContext>
                  </DndContext>
                </div>
              </div>
            )}
          </div>

          {isAddingMeals ? (
            <div className="mt-8 flex flex-row-reverse">
              <Button
                buttonStyle="stroke"
                onClick={() => {
                  setIsAddingMeals(false)
                }}
                size="large"
              >
                Done
              </Button>
            </div>
          ) : (
            <div className="mt-8 flex flex-row-reverse gap-4">
              <ButtonLoading
                isLoading={isUploadingImage}
                size="large"
                type="submit"
              >
                Save
              </ButtonLoading>
              <Button
                buttonStyle="stroke"
                onClick={() => {
                  onClose()
                }}
                size="large"
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      </form>
    </Modal>
  )
}

export default TextImageStackDialog

const customResolver = (values: TextImageStackFormData) => {
  const errors: FieldErrors<TextImageStackFormData> = {}

  if (!values.title) {
    errors.title = {
      message: 'Please enter a title',
      type: 'required',
    }
  }

  if (values.associatedComponentIDs.size === 0) {
    errors.associatedComponentIDs = {
      message: 'Please choose associated meals',
    }
  }

  return {
    values,
    errors,
  }
}
